//import FuseLoadable
import { FuseLoadable } from "@fuse";
//export ClientListConfig
export const EmployeeFundsEmployerConfig = {
  //settings
  settings: {
    //set layout
    layout: {
      //set config as empty object
      config: {}
    }
  },
  //set routes
  routes: [
    {
      path: "/app/ewa/orgList",
      component: FuseLoadable({
        loader: () => import("./Mainworkflow.js")
      })
    }
  ]
  //end routes
};
