// import Auth0Lock from 'auth0-lock';
import jwtDecode from "jwt-decode";
import axios from "axios";
import { AUTH_CONFIG } from "./auth0ServiceConfig";
import CryptoJS from "crypto-js";
import Apiconfig from 'configurations';

class auth0Service {
  sdk = { auth0Manage: null };

  init() {
    /**
     * We are giving Auth0 support for customized login. Therefore, the below code
     * stands unused.
     */
    // this.lock = new Auth0Lock(
    //     AUTH_CONFIG.clientId,
    //     AUTH_CONFIG.domain,
    //     {
    //         autoclose: true,
    //         socialButtonStyle: "big",
    //         auth: {
    //             // redirect: false,
    //             redirectUrl: AUTH_CONFIG.callbackUrl,
    //             responseType: 'token id_token',
    //             audience: `https://${AUTH_CONFIG.domain}/api/v2/`,
    //             params: {
    //                 scope: 'openid profile email user_metadata app_metadata picture update:current_user_metadata create:current_user_metadata read:current_user'
    //             }
    //         }
    //     }
    // );
    // this.lock = false;

    this.handleAuthentication();
  }

  /**
   * Get ntification counts
   */
  getNotification = () => {
    this.load()
      .then(result => {
        this.notification();
      })
      .catch(error => {
        this.notification();
      });
  };

  /**
   * Function to get noyification count
   */
  notification = () => {
    axios
      .get(`/api/rec/items/notification`)
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem("otherAccounts", res.data.data[1].otherAccounts);
          localStorage.setItem("subledger", res.data.data[0].subledgerRec);
          localStorage.setItem(
            "pendingResolve",
            res.data.data[2].pendingResolve
          );
          localStorage.setItem("ledger", res.data.data[3].ledger);
        }
      })
      .catch(error => {
        localStorage.setItem("otherAccounts", 0);
        localStorage.setItem("subledger", 0);
        localStorage.setItem("pendingResolve", 0);
        localStorage.setItem("ledger", 0);
      });
    this.bankRecNotification();
    this.ewaNotification();
  }

  bankRecNotification = () => {
    axios
      .get(`/api/bankRec/items/notification`)
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem("bankrecOtherAccounts", res.data.data[1].otherAccounts);
          sessionStorage.setItem("bankrecSubledger", res.data.data[0].subledgerRec);
          sessionStorage.setItem("bankrecPendingResolve", res.data.data[2].pendingResolve);
          sessionStorage.setItem("bankrecLedger", res.data.data[3].ledger);
        }
      })
      .catch(bnknoterror => {
        sessionStorage.setItem("bankrecOtherAccounts", 0);
        sessionStorage.setItem("bankrecSubledger", 0);
        sessionStorage.setItem("bankrecPendingResolve", 0);
        sessionStorage.setItem("bankrecLedger", 0);
      });
  }

  ewaNotification = () => {
    axios
      .get(`/api/employeeFunds/items/notification`)
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem("ewaSubledger", res.data.data[0].ewaSubledgerRec);
          sessionStorage.setItem("ewaPendingResolve", res.data.data[2].ewaPendingResolve);
          sessionStorage.setItem("ewaLedger", res.data.data[3].ewaLedger);
        }
      })
      .catch(bnknoterror => {
        sessionStorage.setItem("ewaSubledger", 0);
        sessionStorage.setItem("ewaPendingResolve", 0);
        sessionStorage.setItem("ewaLedger", 0);
      });
  }

  load = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/rec/items/subledgers/outstanding`, {
          where: {},
          page: 1,
          limit: 35
        })
        .then(res => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject("Error in load subledger data");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  /**
   * This method will retun the Auth0 Token.
   */

  signInWithEmailAndPassword = (email, password, dispatch) => {
    let encrEmail = CryptoJS.AES.encrypt(
      email.toLowerCase(),
      Apiconfig.cryptoKey
    ).toString();
    let encrPassword = CryptoJS.AES.encrypt(
      password,
      Apiconfig.cryptoKey
    ).toString();
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/api/auth/zero/login",
        config: {
          headers: {
            "content-type": "application/json"
          }
        },
        data: {
          email: encrEmail,
          password: encrPassword
        }
      })
        .then(response => {
          if (response.data.success) {
            if (!response.data.mfa) {
              localStorage.setItem("token", response.data.access_token);
              this.getNotification();
            }
            resolve(response);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  signInWithMfa = (factorParams, mfa_token) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/api/auth/zero/login/mfa",
        config: {
          headers: {
            "content-type": "application/json"
          }
        },
        data: {
          ...factorParams,
          mfa_token
        }
      })
        .then(response => {
          if (response.data.success) {
            localStorage.setItem("token", response.data.access_token);
            this.getNotification();
            resolve(response);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  signInWithMfaFactor = ({mfa_option, enroll_auth}, mfa_token) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/api/auth/zero/login/mfafactor",
        config: {
          headers: {
            "content-type": "application/json"
          }
        },
        data: {
          mfa_token,
          mfa_option,
          enroll_auth,
          email: mfa_option === 'Email' ? localStorage.getItem('email') : ''
        }
      })
        .then(response => {
          response.data.mfa_factor = true;
          resolve(response);
        })
        .catch(error => {
          console.log(error);
        });
    });
  }

  /**
   * This method is replaced with cutomised login
   */
  login = () => {
    if (!this.lock) {
      return false;
    }
    // Call the show method to display the widget.
    this.lock.show();
  };

  /**
   *
   */
  register = () => {
    if (!this.lock) {
      return false;
    }

    this.lock.show({
      initialScreen: "signUp"
    });
  };

  /**
   * This method will handle the authentication
   */
  handleAuthentication = isAuthenticated => {
    if (isAuthenticated) {
      return true;
    }
    if (isAuthenticated) {
      // Add a callback for Lock's `authenticated` event
      this.lock.on("authenticated", this.setSession);
      // Add a callback for Lock's `authorization_error` event
      this.lock.on("authorization_error", err => {
        console.warn(
          `Error: ${err.error}. Check the console for further details.`
        );
      });
    }
  };

  onAuthenticated = callback => {
    // if (!this.lock) {
    //     return false;
    // }
    this.lock.on("authenticated", callback);
  };

  /**
   * This method will set the session
   */
  setSession = authResult => {
    if (authResult && authResult.access_token && authResult.id_token) {
      // Set the time that the access token will expire at
      let expiresAt = JSON.stringify(
        authResult.expires_in * 1000 + new Date().getTime()
      );
      localStorage.setItem("access_token", authResult.access_token);
      localStorage.setItem("id_token", authResult.id_token);
      localStorage.setItem("expires_at", expiresAt);
    }
  };

  /**
   * This method for logout the application & remove the session.
   */
  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("role");
    localStorage.removeItem("auth0.ssodata");
    // localStorage.removeItem('pendingResolve');
    // localStorage.removeItem('subledger');
    // localStorage.removeItem('otherAccounts');
  };

  isAuthenticated = () => {
    // if (!this.lock) {
    //     return false;
    // } //not using lock
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    const isNotExpired = new Date().getTime() < expiresAt;
    if (isNotExpired) {
      this.getNotification();
      return true;
    } else {
      this.logout();
      return false;
    }
  };

  /**
   * @description This method will return the user info.
   */
  getUserData = () => {
    return new Promise((resolve, reject) => {
      const auth0UserUrl = `https://${AUTH_CONFIG.domain}/userinfo`;
      axios
        .get(auth0UserUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAccessToken()
          }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          console.warn("Cannot retrieve user data", error);
          reject(error);
        });
    });
  };

  updateUserData = user_metadata => {
    const tokenData = this.getTokenData();
    const { sub: userId } = tokenData;

    const auth0UserUrl =
      "https://" + AUTH_CONFIG.domain + "/api/v2/users/" + userId;
    const dataObj = JSON.stringify({ user_metadata });

    return axios.patch(auth0UserUrl, dataObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken()
      }
    });
  };

  /**
   * This method will return auth0 AccessToken.
   */
  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  /**
   * This method will return auth0 IdToken.
   */
  getIdToken = () => {
    return window.localStorage.getItem("id_token");
  };

  /**
   * This method will return decode the IdToken.
   */
  getTokenData = () => {
    const token = this.getIdToken();
    const decoded = jwtDecode(token);
    if (!decoded) {
      return null;
    }
    return decoded;
  };
}

const instance = new auth0Service();

export default instance;
