//import FuseLoadable rematchTrans
import { FuseLoadable } from "@fuse";
//export RematchTransConfig
export const EmployeeFundsRematchTransConfig = {
  //rematchTrans settings
  settings: {
    //set rematchTrans layout 
    layout: {
      //set rematchTrans config as empty object
      config: {}
    }
  },
  //set routes
  routes: [
    {
      path: "/app/ewa/RematchTrans",
      component: FuseLoadable({
        loader: () => import("./RematchTransaction.js")
      })
    }
  ]
  //end rematchTrans routes
};
