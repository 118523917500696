//import FuseLoadable rematchTrans
import { FuseLoadable } from "@fuse";
//export ImportErrorMessage
export const EmployeeFundsFileImportActivityConfig = {
  //import File Import Activity settings
  settings: {
    //set import File Import Activity layout 
    layout: {
      //set import File Import Activity config as empty object
      config: {}
    }
  },
  //set routes
  routes: [
    {
      path: "/app/ewa/FileImportActivity",
      component: FuseLoadable({
        loader: () => import("./FileImportActivity.js")
      })
    }
  ]
  //end import File Import Activity routes
};
