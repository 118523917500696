import React from 'react';
import { 
    withStyles, Grid, Icon, 
    Button, Dialog, DialogTitle, DialogActions, DialogContent,
    AppBar, Toolbar, IconButton, TextField, InputAdornment,
    MenuItem
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import moment from 'moment';
import Autocomplete from '../content/apps/employeeFunds/employeeFundsPanel/Autocomplete';
import ClientDialog from '../content/apps/employeeFundsTasks/employerList/ClientDialog';
import {
  platformHrThemeColor, disableHtmlElement, genericLoadingProgress
} from '../../genericFunctions';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse';
import PartnerLedgerAutoComplete from '../../../src/main/content/apps/employeeFunds/employeeFundsPanel/PartnerAutoComplete';

const useStyles = () => ({
    employerName: { width: '257px', border:'1px solid lightgray', height: '50px', padding: '6px'},
    status: {
      width: '226px', border:'1px solid lightgray', height: '50px', padding: '6px'
    },
    tooltip: {width: '120px',backgroundColor: '#585858',color: '#ffffff',textAlign: 'center',borderRadius: '6px',
      padding: '5px 0px',zIndex: 1,float: 'right',position: 'absolute', left: '85%', fontSize: '12px', bottom: '10%'}
})

class AddTransaction extends React.Component {

    state = {
      employerName: '', employer: '', partner: '', accountName: '', partnerName: '',
      transactionDate: moment(new Date()).format("YYYY-MM-DD"), showPartnerLedgerEmployer: '',
      sourceDate: moment(new Date()).format("YYYY-MM-DD"), loadingLedger: false,
      details: '', amount: 0, ledgerObjId: '', transactionType: '', objid: null,
      account: '', newAccntSel: '', manualCheck: false, customer: '', cleared: null, sourceFile: 'ADDED', fileHash: ' ',
      recType: '', memo: '',  ledgerId: 0, accountId: '', ledger: '', amtChk: false, lineNo: -1,
      clientEmployerName: [], openDialogClients: false, chkAddClient: 'add', empId: '', empName: '', saveAddTransDisab: false,
      empStatus: 'Active', employerList: [], employerTransSnack: false, employerTransMessage: '', hover: false
    }

    async componentDidMount() {
      try {
        this.populateDetails();
        await Promise.all([this.ledgerParseCode(), this.ledgerConfigDetails(), this.tenantConfigurataion()]);
        
      }
      catch(errLoadConfig) {
        console.error(errLoadConfig); this.setState({saveAddTransDisab: false, loadingLedger: false});
      } 
    }

    populateDetails = () => {
      const {populate, undeterMineObj} = this.props
      if(populate) {
        const {
          transactionDate, details, accountName, amount, ledger, ledgerId, accountId, lineNo,
          transactionType, memo, customer, employer, sourceFile, fileHash, sourceDate
        } = undeterMineObj;
        this.setState({
          details: details ? details : '', amtChk: true, amount: Number(amount.toFixed(2)), ledger : ledger ? ledger : '',
          ledgerId: ledgerId ? ledgerId : 0, accountName: accountName ? accountName: '', accountId: accountId ? accountId : '', newAccntSel: accountName ? accountName : '',
          transactionDate: transactionDate ? moment(transactionDate).format('YYYY-MM-DD'): '', transactionType: transactionType ? transactionType : '', memo: memo ? memo : '',
          accountId, customer: customer ? customer : '', employer: employer ? employer: '', sourceFile: sourceFile ? sourceFile : 'ADDED',
          fileHash: fileHash ? fileHash : ' ', lineNo: lineNo ? lineNo : -1, sourceDate: sourceDate ? moment(sourceDate).format('YYYY-MM-DD'): '', loadingLedger: true,
          saveAddTransDisab: true
        }, ()=>{
          this.setState({amtChk: false});
        });
      }
    }

    tenantConfigurataion = async() => {
        let chkServiceq = 'Bank Rec';
        try {
            const verifyServiceadd = await axios.get(`/api/rec/items/agent/tenantConfig?service=${chkServiceq}`);
            if(verifyServiceadd.data && verifyServiceadd.data.status === 200) {
                const typeadd = verifyServiceadd.data.data; 
                this.setState(()=>({reconcilList: typeadd.length ? typeadd[0]['recType'] :[]}));
            }
        }
        catch(errsqa) {
            console.error(errsqa);
        }
      }

    ledgerParseCode = async () => {
        try {
            const ledgres = await axios.post(`/api/bankRec/items/agent/ledgerParseCode`,{
              select:{accounts: 1, transactionCodes: 1}
            });
            if(ledgres.data.data && ledgres.data.data.length) {
              const {transactionCodes, accounts: listacct} = ledgres.data.data[0];
              const transCodeList = transactionCodes ? transactionCodes.map(sca=>sca.transDesc): [];
              this.setState({transCodeList, listacct});
            }
        }
        catch(errs) {
            console.error(errs);
        }
    }

    ledgerConfigDetails = async () => {
        try {
        const ledresq = await axios.get('api/rec/tenant/ledgerConfig');
        if(ledresq.status === 200) {
            this.setState(()=>({configDetails: ledresq.data.data.configDetails}));
        }
        }
        catch(lederrq) {
            console.error(lederrq);
        }
     }

    handleClose = (close=false) => {
      const {closeLedger} = this.props;
        this.setState({ 
          details:'', amount:0, transactionType: '', partnerName: '',
          transactionDate: moment(new Date()).format("YYYY-MM-DD"),
          sourceDate: moment(new Date()).format("YYYY-MM-DD"),
          employerName:'', newAccntSel: '', manualCheck: false, recType: '', memo: '', ledger: '', amtChk: false
        }, ()=>{
          closeLedger(false);
        });
    }

    handleChangeManualAdd = evt => {
        this.setState({manualCheck: evt.target.checked});
    }

    handleChange = (evt, achk) => {
      let val = evt.target.value;
        if (achk) {
          this.setState({[achk]: achk === 'amount' ? val.indexOf('.') !== -1 ? !(val.split('.')[1].length >= 3) && Number(Number(val).toFixed(2)) : Number(Number(val).toFixed(2)) : val, amtChk: true},()=>{
            const {newAccntSel, configDetails, listacct: accountList} = this.state;
            if(achk === 'newAccntSel' && newAccntSel) {
                let accnInfo = configDetails.filter(cfg=>cfg.bankAccounts.find(an=>an.name === newAccntSel));
                if(accnInfo.length) {
                  let {accountId} = accnInfo[0]['bankAccounts'].find(j=>j.name === newAccntSel);
                  let ledgerId = accnInfo[0]['ledgers'][0]['ledgerId'];
                  this.setState({
                    ledger: accountList.filter(g=>g.accountName === newAccntSel)[0]['ledger'],
                    ledgerId, accountId, accountName: accountList.filter(g=>g.accountName === newAccntSel)[0]['accountName']
                  });
                }
            } else {
              this.setState({amtChk: false});
            }
          });
        }
        if (achk && (achk === "transactionDate" || achk === "sourceDate")) {
          let date = val; let startYear = 1900; let endYear = 2100;
          let currentYear = parseInt(date.substring(0, 4));
          if (currentYear >= startYear && currentYear <= endYear)
              this.setState({[achk]: val});
        }
    }

    saveLedgerTransactions = async () => {
        const {
          details, amount, transactionDate, employerName, ledger, customer, ledgerId, partnerName,
          memo, transactionType, partner, employer, accountName, accountId, sourceFile, fileHash, lineNo, sourceDate
        } = this.state;
        const {closeLedger} = this.props;
        const tmpSource = sourceDate ? {sourceDate} : {};
        try {
          this.setState({saveAddTransDisab: true});
          await axios.post(`/api/employeeFunds/items/bankRecLedger/newBankLedger`, {
            data: { memo, details, amount, transactionDate, employerName, employer, ledger, transactionType, addNew: true,
              ledgerId, accountId, customer, partner, sourceFile, fileHash, lineNo, partnerName,
              autoMatch: true, accountName, ...tmpSource
            }
          });
          this.setState({saveAddTransDisab: false}); closeLedger(true);
          this.handleClose();
        }
        catch(err) {
          console.error(err); this.setState({saveAddTransDisab: false});
        }
      }

      onSelectChange = selectObj => this.setEmployerLedgVal(selectObj)

      setEmployerLedgVal = ({employer, employerName, partner, partnerName, mount}) => this.setState({
        employer, employerName, partner, partnerName, saveAddTransDisab: false, loadingLedger: false,
        showPartnerLedgerEmployer: !mount ? 'employer' : mount && partner ? 'employer' : ''
      });

      handleClientClose = () => {
        this.setState({ 
            openDialogClients: false,
            chkAddClient: 'add',
            empName: '',
            empStatus: 'Active', empId: ''
        });
    }

    openCliDialog = (empId, openDialogClients) => {
      this.setState({empId, openDialogClients})
    }

    saveClientData = async (impchk, evt) => {
      const {empId, empName, empStatus} = this.state;
      let addTransCliObj={};
      if(impchk && impchk === 'add') {
        addTransCliObj = {
            employer: empId.trim().toUpperCase(),
            employerName: empName.trim(),
            status: empStatus, "edited" : false,
            partnerId : "", partnerName : "", platform : "", achDescription: ""
        }
      }
      try {
          const transres = await axios.put('api/employeeFunds/items/addUpdateClient/client',{
            pushInfo: addTransCliObj
          });
          const transResponse = transres.data.data;
            if(typeof transResponse !== 'string' && Object.keys(transResponse).length) {
                // await this.fetchBankRecTransactionClients();
            } else {
                this.setState({
                  employerTransSnack: !Array.isArray(transResponse) ? true: false,
                  employerTransMessage: !Array.isArray(transResponse) ? `${empId.trim()} already exist in the system` : '',
                });
            }
          const {clientEmployerName} = this.state;
          const finalCo = empId  && empId.trim() ? clientEmployerName.filter(p=>p.indexOf(empId.trim())!==-1)[0] : '';
          this.setState({employerName: finalCo ? finalCo : ''});
          this.handleClientClose();
      }
      catch(err) {
          console.error(err);
      }
  }

  snackbarhandleTransClientClose = () => {
    this.setState({employerTransSnack: false});
  }

  handleMouseIn() {
    this.setState({ hover: true })
  }
  
  handleMouseOut() {
    this.setState({ hover: false })
  }

  isValidTransValue = () => {
    const {transCodeList, transactionType} = this.state;
    if(Array.isArray(transCodeList) && transCodeList.length)
      return transCodeList.some(sa => typeof sa === 'string' && typeof transactionType === 'string' && sa.toLowerCase() === transactionType.toLowerCase())
    return false;
  }

  onSelectAddLedgerPartnerChange = ({partner, partnerName}) => this.setState({ partner, partnerName, showPartnerLedgerEmployer:'tmpPartner' })


    render() {
        const {
          classes, openDialogLedger, populate, undeterMineObj
        } = this.props;
        const {
            transactionDate, details, amount, transactionType, openDialogClients, loadingLedger, showPartnerLedgerEmployer,
            memo, newAccntSel, amtChk, customer, transCodeList, listacct: accountList, saveAddTransDisab,
          chkAddClient, empName, empStatus, empId, employer, hover, partner, sourceDate, partnerName
        } = this.state;
        const overRideLedgerPartnerClass = {top:'58px', width:'455px'};
        const tooltipStyle = {
          display: hover ? 'block' : 'none'
        }
        const amtobj = amtChk && typeof amount === 'number' ? {value: amount.toFixed(2)} : {};
        let enableSave = false;
        if(chkAddClient === 'add' && empId.trim() && empName.trim()) {
          if(empStatus) {
              enableSave = true;
          }
        }
        const showParnterLedgerField = `${partner}${partnerName ? ` - ${partnerName}`: ''}`;
        const disableSaveAdd = saveAddTransDisab ? {...disableHtmlElement} : {};
        const {employer: tmpLedgEmployer} = populate && undeterMineObj ? undeterMineObj : {};
        const loadLedgerStyle = {load: loadingLedger, genStyle:{color: platformHrThemeColor, position: 'fixed', top:'48vh', left: '48%', zIndex: 999}, circularStyle:{width:'70px',height:'70px'}};
        return (
          <>
            <Dialog
          open={openDialogLedger} onClose={this.handleClose}
          disableBackdropClick disableEscapeKeyDown fullWidth
          maxWidth={'md'}
         >
        <DialogTitle component="div" className={`p-0 ${saveAddTransDisab ? 'pointer-events-none opacity-50' : ''}`}>
        <AppBar style={{ color: 'white', backgroundColor:  platformHrThemeColor }} position="static" elevation={1}>
                    <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16" style={{ overflowX: "scroll", overflowY: "hidden" }}>
                    <div className="flex flex-1"> {'Add Ledger Transaction'}
                    </div>
                    <IconButton color="inherit" ><Icon onClick={this.handleClose}>close</Icon></IconButton>
                    </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={`p-16 sm:p-24 ${saveAddTransDisab ? 'pointer-events-none opacity-50' : ''}`}>
          {genericLoadingProgress({...loadLedgerStyle})}
          <div className="mb-16 sm:mb-8 flex">
          <Formsy
            ref={(form) => this.form = form}
            className="flex flex-col justify-center w-full"
          >
          <Grid container spacing={3}>
          {amount === false && <Grid item xs={12} style={{ color: 'red', textAlign: 'center', marginBottom: '14px' }}>Only two digits after decimal Point!</Grid>}
            <Grid item xs={12} sm={6}>
              <Autocomplete
                onSelectChange={this.onSelectChange} setEmployerVal={this.setEmployerLedgVal}
                openCliDialog={this.openCliDialog} coName={tmpLedgEmployer} 
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '28px', marginBottom:'25px'}}>
              <TextFieldFormsy
                id="date" label="Transaction Date *" type="date" value={moment(transactionDate).format('YYYY-MM-DD')} name="date" fullWidth
                variant="outlined" onChange={evt => this.handleChange(evt, 'transactionDate')} InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'6px' }}>
              <PartnerLedgerAutoComplete
                    onSelectChange={this.onSelectAddLedgerPartnerChange}
                    coName={showParnterLedgerField} topClass={overRideLedgerPartnerClass} //showPartnerEmployer={showPartnerEmployer}
                    placeholderText={'Partner *'} searchText={true} required={true} showPartnerEmployer={showPartnerLedgerEmployer}
                />              
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingLeft: '28px', position:'relative', bottom:'6px', marginBottom:'18px'}}>
              <TextFieldFormsy
                id="date" label="Source Date" type="date" value={moment(sourceDate).format('YYYY-MM-DD')} name="date" fullWidth
                variant="outlined" onChange={evt => this.handleChange(evt, 'sourceDate')} InputLabelProps={{ shrink: true }}
              />              
            </Grid>
            <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'6px' }}>
              <TextFieldFormsy
                margin="dense" id="customer" label={<span style={{position:'relative', bottom: customer ? '0px' : '2px'}}>Customer</span>} name="customer"
                type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'customer')} value={customer}
                fullWidth
                multiline
                rows={1}
                inputProps={{style:{minHeight:'20px'}}}
              />                              
            </Grid>

            <Grid item xs={12} sm={6} style={{ position:'relative', bottom:'15px', paddingLeft: '29px'}}>
              <TextField
                margin="dense" id="name" label="Amount *" type="number" variant="outlined" onBlur={evt => this.handleChange(evt, 'amount')}
                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : ''} {...amtobj}
                InputProps={{
                  style: {
                    height: '55px'
                  },
                  startAdornment: (<InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>),
                }}
                fullWidth
              />                  
            </Grid>

            <Grid item xs={12} sm={6} style={{ position:'relative', top:'0px' }}>
              <TextField
                name='transactionType' select
                value={this.isValidTransValue() ? transactionType : ''} label={<span style={{position:'relative', bottom: transactionType ? '0px' : '2px'}}>Trans Type</span>} 
                onChange={evt => this.handleChange(evt, 'transactionType')}  variant="outlined" fullWidth
              >
                <MenuItem value={""}>Select Trans Type</MenuItem>
                {
                  transCodeList && transCodeList.length > 0 && transCodeList
                    .sort((ta, tb) => (ta.toLowerCase() > tb.toLowerCase()) - (ta.toLowerCase() < tb.toLowerCase()))
                    .map(trans =>
                      <MenuItem value={trans}>{trans}</MenuItem>
                    )
                }
              </TextField>                  
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingLeft: '30px', position:'relative', top:'0px' }}>
              <TextField
                value={newAccntSel ? newAccntSel : ''} onChange={evt => this.handleChange(evt, 'newAccntSel')} name="account"
                variant="outlined" label={<span style={{position:'relative', bottom: newAccntSel ? '0px' : '2px'}}>Account *</span>} fullWidth select
              >
                <MenuItem value={""}>Select Account *</MenuItem>
                {
                  accountList && accountList.length > 0 && accountList.map(v => v.accountName)
                    .sort((a, b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                    .map(acct => <MenuItem value={acct}>{acct}</MenuItem>)
                }
              </TextField>                
            </Grid>               
                
            <Grid item xs={12} sm={6} style={{ marginTop:'20px' }}>
              <TextFieldFormsy
                  margin="dense" id="name" label="Details" name="Details"
                  type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'details')} value={details}
                  multiline
                  rows={3}
                  fullWidth
                />
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingLeft: '30px', position:'relative', top:'20px' }}>
              <TextFieldFormsy
                  margin="dense" id="name" label="Notes" name="Memo"
                  type="text" variant="outlined" onChange={evt => this.handleChange(evt, 'memo')} value={memo}
                  multiline
                  rows={3}
                  fullWidth
                />                
            </Grid>                
          </Grid>
              {/* {employerTransSnack &&
                snackBarComponent({
                  clientSnack: employerTransSnack, duration: 4000, anchorigin: { vertical: 'bottom', horizontal: 'right' }, snackmessage: employerTransMessage,
                  snackbarhandleClose: this.snackbarhandleTransClientClose
                })
              } */}
            </Formsy>
          </div>
        </DialogContent>
        {amount && details && transactionDate && employer && newAccntSel && partner ? '' : <div style={{position: 'relative'}}>
          <div className={classes.tooltip} style={tooltipStyle}>{amount && details && transactionDate && employer && newAccntSel && partner ? '' : 'Please fill out the required (*) fields'} </div>
        </div>}
        <DialogActions className={`${saveAddTransDisab ? 'pointer-events-none opacity-50' : ''}`}>
          <Button 
            autoFocus  onClick={this.handleClose}  style={{color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}}
            > Cancel </Button>
          <Button 
            // title={amount && details && transactionDate && employerName.trim() && newAccntSel ? '' : 'Please fill out the required (*) fields'}
            onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}
            onClick={this.saveLedgerTransactions} 
            style={amount && transactionDate && employer && newAccntSel && partner ? {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...disableSaveAdd}:
            {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', pointerEvents:'auto', opacity:0.5}}
            disabled={amount && transactionDate && employer && newAccntSel && partner ? false : true}
            >
            {!loadingLedger && saveAddTransDisab ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
        </Dialog>
        {
              openDialogClients && 
              <ClientDialog 
                  openDialogClients={openDialogClients}
                  handleClose = {this.handleClientClose}
                  handleChange = {this.handleChange}
                  saveClientData = {this.saveClientData}
                  empId={empId} empName={empName}
                  empStatus={empStatus} chkAddClient={chkAddClient} enableSave={enableSave}
                  classes={classes}
              />
          }
        </>
        )
    }
}

export default withStyles(useStyles)(AddTransaction);