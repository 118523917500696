import React , { createRef } from "react";
import PropTypes from "prop-types";
import "./partnerAutoComplete.css";
import { Icon, TextField, InputAdornment} from "@material-ui/core";
import partnerLodAsh from '@lodash';
import axios from 'axios';

class PartnerAutocomplete extends React.Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);
    //START: Bug 5010 Search Employer Bug
    this.ewaDropDownRef = createRef();
    this.ewaCloseDropDown = this.ewaCloseDropDown.bind(this);
    //END: Bug 5010 Search Employer Bug
    this.state = {
      // The active selection's index
      ewaActiveSuggestion: -1,
      // The suggestions that match the user's input
      ewaClientfiltSuggestions: [],
      // Whether or not the suggestion list is shown
      ewaClientListshowSuggestions: false,
      // What the user has entered
      ewaUserClientInput: props.coName ? props.coName: "",
      //initial input
      ewaUsrClientInput: "",
      //onclick flag
      flag: false, ewaTmpPartnerListSuggestion: []
    };
    this.debouncedMainPartnerSearch = partnerLodAsh.debounce(this.ewaMainPartnerSearch, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    const { coName, showPartnerEmployer, unCheckPartner} = this.props;
    const { showPartnerEmployer: tmpPartEmpl } = prevProps;
    const { ewaUserClientInput } = prevState;
    const {flag} = this.state;
    if (typeof showPartnerEmployer === 'string' && typeof tmpPartEmpl === 'string') {
        if (showPartnerEmployer === 'employer' && typeof coName === 'string' && typeof ewaUserClientInput === 'string') {
          if (coName !== ewaUserClientInput && !flag)
            this.setState({ ewaUserClientInput: coName });
        }
    }
    if(typeof unCheckPartner === 'boolean' && prevProps.unCheckPartner !== unCheckPartner && coName !== ewaUserClientInput) 
          this.setState({ewaUserClientInput: ''});
  }
  

  componentDidMount() {
    this.fetchPartnerList({mount: true, limit: true})
    //START: Bug 5010 Search Customer Bug: Register one event for click away listener...
    document.addEventListener("mousedown", this.ewaCloseDropDown);
    //END: Bug 5010 Search Employer Bug
  }

  //START: Bug 5010 Search Employer Bug : De-Register one event for click away listener...
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.ewaCloseDropDown);
  }

  ewaCloseDropDown(e) {
    if (
      this.state.ewaClientListshowSuggestions &&
      this.ewaDropDownRef.current &&
      !this.ewaDropDownRef.current.contains(e.target)
    ) {
        this.setState({
          ewaActiveSuggestion: 0,
          ewaClientfiltSuggestions: [],
          ewaClientListshowSuggestions: false,
        });
    }
  }

  fetchPartnerList = async ({showSuggest, limit, mount}) => {
    try {
      const { ewaUserClientInput } = this.state;
      const setInput = !mount ? ewaUserClientInput : '';
      let ewaTmpPartnerListSuggestion = [];
      const response = await axios.post('/api/employeeFunds/fetchPartnerList', { ewaUserClientInput: setInput  , limit});
      const {resJson: filtsug} = response.data.data;
      ewaTmpPartnerListSuggestion = filtsug;
      const tmpSuggest = showSuggest ? {ewaClientfiltSuggestions: [...ewaTmpPartnerListSuggestion], ewaClientListshowSuggestions: true} : {};
      this.setState({
        ewaActiveSuggestion: -1, ewaTmpPartnerListSuggestion, ...tmpSuggest
        //  flag: true
      });
    }
    catch (errEmployerListError) {
      console.error(errEmployerListError);
    }
  }

  ewaMainPartnerSearch = () => this.fetchPartnerList({showSuggest: true})

  onChange = e => this.setState({ ewaUserClientInput: e.currentTarget.value, ewaUsrClientInput: e.currentTarget.value, flag: true }, () => {
    const { ewaUserClientInput: tmpOnChangeEmpInput } = this.state;
    const {onSelectChange} = this.props;
    if (typeof tmpOnChangeEmpInput === 'string' && tmpOnChangeEmpInput.trim())
        this.debouncedMainPartnerSearch()
    else 
      onSelectChange({ partner: '', partnerName: ''})
  })

  onClick = (e, ewaUserClientInput) => {
    const {partnerSearchText, partner, partnerName, parent: partnerFlag} = ewaUserClientInput && typeof ewaUserClientInput === 'object' ? ewaUserClientInput : {}; 
    const {onSelectChange, toInd, money, coTxt} = this.props;
    // const obj = money ? {toInd} : {};
    const clickIndTxt = money && typeof toInd === 'number' ? toInd: '';
    if(money) {
      const onUnClickTab = document.getElementById(`MoneyDropDownPartner${clickIndTxt}`);
      onUnClickTab.focus();
    } else {
      const onUnClickAddTab = document.getElementById(`ErrorDropDownPartner${toInd ? toInd: ''}`);
      onUnClickAddTab.focus();
    }
    this.setState({
      ewaActiveSuggestion: 0,
      ewaClientfiltSuggestions: [],
      ewaClientListshowSuggestions: false,
      ewaUserClientInput: partnerSearchText,
      flag: false
    }, ()=>
        onSelectChange({
          partner: partner ? partner : '', partnerFlag,
          partnerName: partnerName ? partnerName : '', coTxt
        })
    );
  };

  scrollList = suggestion =>{
    const {money} = this.props;
    let list  = money ? document.querySelector('.moneysuggest-active') : document.querySelector('.partnersuggestion-active');
    let ullist  = money? document.querySelector('.moneysuggest') : document.querySelector('.partnersuggestions');
  
    let elHeight = list.clientHeight;
    let scrollTop = ullist.scrollTop;
    let viewport = scrollTop + ullist.clientHeight;
    let elOffset = elHeight * suggestion;
    
    if (elOffset < scrollTop || (elOffset + elHeight) > viewport)
      ullist.scrollTop=elOffset;
}

  onKeyDown = e => {
    const {money} = this.props;
    let downlist  = money ? document.querySelector('.moneysuggest') : document.querySelector('.partnersuggestions');
    const { ewaActiveSuggestion, ewaClientfiltSuggestions, ewaClientListshowSuggestions} = this.state;

    if (e.keyCode === 13 && e.target.value.trim()!=='' && ewaClientfiltSuggestions.length) {
      if(ewaClientListshowSuggestions && ewaActiveSuggestion!==-1) {
            const suggest = ewaClientfiltSuggestions[ewaActiveSuggestion];
              this.onClick('', suggest);
      }
    }
    // User pressed the up arrow
    else if (e.keyCode === 38 && e.target.value.trim()!=='') {
     if(this.state.ewaClientListshowSuggestions && ewaActiveSuggestion!==-1) {
     this.scrollList(ewaActiveSuggestion-1);
     }

      if(ewaActiveSuggestion === -1) {
        downlist.scrollTop = downlist.scrollHeight;
      this.setState({
        ewaActiveSuggestion: ewaClientfiltSuggestions.length-1
      });
      return;
    }

      if (ewaActiveSuggestion === 0) {
        this.setState({
          ewaActiveSuggestion: -1,
          ewaUserClientInput: this.state.ewaUsrClientInput
        });
        return;
      }

      this.setState({ ewaActiveSuggestion: ewaActiveSuggestion - 1 });
    }
    // User pressed the down arrow
  else if (e.keyCode === 40 && e.target.value.trim()!=='') {
   if(this.state.ewaClientListshowSuggestions && ewaActiveSuggestion!==-1) {
      this.scrollList(ewaActiveSuggestion+1);
   }

     if (ewaActiveSuggestion - 1 === ewaClientfiltSuggestions.length-2) {
       downlist.scrollTop=0;
       this.setState({
         ewaActiveSuggestion: -1,
         ewaUserClientInput: this.state.ewaUsrClientInput
         });
         return;
     }
      this.setState({ ewaActiveSuggestion: ewaActiveSuggestion + 1 });
    }
  }

  clearInput = () => {
      const {onSelectChange, toInd, money, coTxt} = this.props;
      const {ewaTmpPartnerListSuggestion} = this.state;
      const clearObj = money ? {toInd} : {};
      this.setState({ewaUserClientInput: '', flag: false,
      ewaClientListshowSuggestions: true, ewaClientfiltSuggestions: [...ewaTmpPartnerListSuggestion]
      }, ()=> {
        this.fetchPartnerList({showSuggest: true, limit: true});
        onSelectChange({partnerName:'', partner:'', coTxt})
      });
  }

  showSuggestion = (evt, inp) => {
    const {ewaTmpPartnerListSuggestion} = this.state;
    const onUnClick = document.getElementById(inp);
      if(onUnClick) {
        onUnClick.focus();
      }
      this.setState({
        ewaUserClientInput: '', ewaClientListshowSuggestions: true, flag: false,
        ewaClientfiltSuggestions: [...ewaTmpPartnerListSuggestion]
      },()=> {
        this.fetchPartnerList({showSuggest: true, limit: true});
        this.props.onSelectChange({partnerName:'', partner:''})
      });
  }

  onBlurDropDown = () => {
    this.setState({ewaClientListshowSuggestions: false, ewaClientfiltSuggestions:[]});
  }

  onFocusDropDown = () => {
    const {ewaTmpPartnerListSuggestion} = this.state;
    this.setState({ewaClientListshowSuggestions: true, ewaClientfiltSuggestions: [...ewaTmpPartnerListSuggestion]});
  }

  render() {
    const {
      onChange,
      onKeyDown,
      state: {
        ewaActiveSuggestion,
        ewaClientfiltSuggestions,
        ewaClientListshowSuggestions,
        ewaUserClientInput
      }
    } = this;
    let suggestionsListComponent;
    const {money, toInd, topClass, autoCompleteClass,inputStyleProps, placeholderText, searchText, required} = this.props;
    const indTxt = money && typeof toInd === 'number' ? toInd: '';
    const overRideStyle = topClass ? {...topClass} : {};
    const {inputStyle, labelStyle} = autoCompleteClass ? autoCompleteClass : {};
    const iputStyleProps = inputStyleProps ? inputStyleProps : {};
    if (ewaClientListshowSuggestions) {
      if (ewaClientfiltSuggestions.length) {
        suggestionsListComponent = (
          //START: Bug 5010 Search Employer Bug : use reference of mouse event on parent div...
          <ul className={money ? 'moneysuggest' : 'partnersuggestions'} style={{...overRideStyle,...iputStyleProps}} ref={this.ewaDropDownRef}>
            {ewaClientfiltSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === ewaActiveSuggestion) {
                className = money ? "moneysuggest-active" : "partnersuggestion-active";
              }
              return (
                <>
                  {typeof suggestion !== 'string' && <li className={className} key={suggestion._id} onClick={e => this.onClick(e, suggestion)} role="option">
                    {suggestion.partnerSearchText}
                  </li>
                  }
                </>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <>
        <TextField
              label={<span style={labelStyle ? {...labelStyle}:{position:'relative', bottom:'2px'}}>{`${!ewaUserClientInput && searchText ? `Search Partner${required ? ' *': ''}` : placeholderText}`}</span>}
              type="text" variant="outlined"
              fullWidth value={ewaUserClientInput}
              inputProps={{
                  'aria-label': ''
              }}
              InputLabelProps={{style:{paddingTop: '0px'}}}
              InputProps={{
                style:inputStyle ? {...inputStyle}:{},
                endAdornment: (
                  <InputAdornment position="end">
                    {ewaUserClientInput && (
                      <Icon aria-hidden="true" style={{cursor: 'pointer',fontSize:'20px'}} onClick={evt =>this.clearInput(evt)}>clear</Icon>
                    )}
                    <Icon aria-hidden="true" style={{cursor: 'pointer',fontSize:'20px'}} onClick={evt =>this.showSuggestion(evt, "ErrorDropDownPartner")}>arrow_drop_down</Icon>
                  </InputAdornment>
                )
              }}
              onChange={onChange} autoComplete="off"
              onKeyDown={onKeyDown} id={money ? `MoneyDropDownPartner${indTxt}` : `ErrorDropDownPartner${toInd ? toInd: ''}`}
              onFocus={this.onFocusDropDown.bind(this)}
        />
        {suggestionsListComponent}
      </>
    );
  }
}

export default PartnerAutocomplete;