import { EmployeeFundsPanelConfig } from '../employeeFunds/employeeFundsPanel/index';
import { EmployeeFundsLedgerRecConfig } from '../employeeFunds/ledgerRec/index';
import { EmployeeFundsKanbanConfig } from '../employeeFunds/kanban/index';
import { EmployeeFundsImportLogConfig } from '../employeeFunds/importLogs/index';
import { EmployeeFundsDashboardConfig } from '../employeeFunds/dashboard/index';
import { EmployeeFundsReportComponentsConfig } from '../employeeFundsReports/index';
import { EmployeeFundsEmployerConfig } from '../employeeFundsTasks/employerList/index';
import { EmployeeFundsFileImportActivityConfig } from '../employeeFundsTasks/fileImportActivity/index';
import { EmployeeFundsRematchTransConfig } from '../employeeFundsTasks/reMatchTrans/index';
import { EmployeeFundsSearchLedgerConfig } from '../employeeFundsTasks/searchLedger/index';
import { EmployeeFundsErrorMessageConfig } from '../employeeFundsTasks/importErrorMessage/index';
import { EmployeeFundsReportsConfig } from '../employeeFundsIndexReport/index';
import { EmployeeFundsSubledgerRecConfig } from '../employeeFunds/subledgerRec/index';


export const employeeFundsConfig = [
    EmployeeFundsPanelConfig, EmployeeFundsLedgerRecConfig, EmployeeFundsKanbanConfig, EmployeeFundsEmployerConfig, EmployeeFundsRematchTransConfig,
    EmployeeFundsImportLogConfig, EmployeeFundsDashboardConfig, EmployeeFundsReportComponentsConfig, EmployeeFundsFileImportActivityConfig,
    EmployeeFundsSearchLedgerConfig, EmployeeFundsErrorMessageConfig, EmployeeFundsReportsConfig, EmployeeFundsSubledgerRecConfig
];