import React, { Component, useState, useEffect, useRef} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { withStyles, LinearProgress  } from "@material-ui/core";
import classNames from "classnames";
import momentTimezone from "moment-timezone";
import {
  List, Tooltip, ListSubheader,
  Divider,
  ListItem,
  Avatar,
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography, Dialog, DialogContent
} from "@material-ui/core";
import { connect } from "react-redux";
import * as quickPanelActions from "main/quickPanel/store/actions";
import * as authActions from "auth/store/actions";
import * as chatPanelActions from "main/chatPanel/store/actions";
import { bindActionCreators } from "redux";
import { FuseAnimate } from "@fuse";
import { Link } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import Apiconfig, {socket} from 'configurations';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "../../src/history";
import Badge from "@material-ui/core/Badge";
import auth0Service from "services/auth0Service";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import UpdateUserProfile from "./UpdateUserProfile";
import fileSaver from "file-saver";
import b64ToBlob from "b64-to-blob";
import {millisToMinutesAndSeconds, platformHrThemeColor, saveFile, getCurrentTimeZone} from '../genericFunctions';
import CheckCircle from '@material-ui/icons/CheckCircle';
import store from "store";
import ErrorIcon from '@material-ui/icons/Error';
import AlertDialogScreenLeave from '../AlertDialogScreenLeave';

const {redirectlogin, bankRecHelp, clientFundsHelp, emailSupport, empFundHelp} = Apiconfig;
let prevState = "null";
const styles = theme => ({
  darkme: { backgroundColor: "#014d72" },
  list: {
    top: 65,
    width: 250
  },
  fullList: {
    width: "auto"
  },
  root: {
    top: 65,
    backgroundColor: "#fdfdfd",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  topme: {
    top: 65
  },

  whitecol: {
    right: 100,
    color: "#767676"
  },
  cust: {
    color: "#8b8b8b"
  },
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider
  },
  padding: {
    paddingTop: "3px",
    paddingLeft: "8px"
  },
  docsLink: {textDecoration:'none', color:'#000000'}
});

const Timer = ({timeout, remainTime, tologout}) => {
  const [state, setState] = useState('Active');
  const [mainToolInterValId, setMainInterValId] = useState(null);
  const [outIntervalId, setOutInterValId] = useState(null);
  const [openToolbarModal, setOpenToolModal] = useState(false);
  const [timer, setTimer] = useState('');
  const valueRef = useRef();

  const onIdle = () => {
    setState('Idle')
  }

  const onActive = () => {
    setState('Active')
  }

  const onMessage = msg => {
    if(msg && msg === 'signedin')
         closeDialogTimer(); 
    else
         signOut();
  }
  
  const {
    getRemainingTime,
    message
  } = useIdleTimer({
    element:document,
    onIdle,
    onActive,
    onMessage,
    timeout,
    crossTab: true,
    syncTimers: 200
  });

  useEffect(() => {
    valueRef.current = { mainToolInterValId, outIntervalId };
  },[mainToolInterValId, outIntervalId]);

  const clearAllInterVal = () => {
    clearInterval(valueRef.current.mainToolInterValId);
    clearInterval(valueRef.current.outIntervalId);
  }

  useEffect(() => {
    checkIdleTime();
    return () => {
      clearAllInterVal();
    }
  },[]);

  const checkIdleTime = () => {
    const mainToolInterValId = setInterval(() => {
      const time = millisToMinutesAndSeconds(getRemainingTime());
      if(time && time.length) {
        const min = parseInt(time.split(':')[0]);
        // console.log(`time ${time} min ${min} remain ${remainTime}`);
        const getRemTime = getRemainingTime();
        if(min < remainTime) {
          clearInterval(mainToolInterValId); setOpenToolModal(true);
          startTimeOut(getRemTime);
        }
      }
  }, 1000);
  setMainInterValId(mainToolInterValId);
  }

  const startTimeOut = millisecond => {
    const start = new Date();
    start.setTime(millisecond);
    let countDownTime = start;
    const outIntervalId = setInterval(()=>{
      const minutes = Math.floor((countDownTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((countDownTime % (1000 * 60)) / 1000);
      // console.log(`minutes ${minutes} and seconds ${seconds}`);
      if(countDownTime < 0) {
        clearInterval(outIntervalId);
        signOut();
      }else {
        let tmpTimer = minutes+":"+ (seconds < 10 ? '0' : '') + seconds;
        setTimer(tmpTimer);
      }
      countDownTime -= 1000;
    },1000);
    setOutInterValId(outIntervalId);
  }

  const _onIdle = e => {
    console.log("user is idle", e);
    //  const isTimedOut = this.state.isTimedOut;
    history.push("/");
    console.log("history is been executed!");
    localStorage.removeItem("token");
    auth0Service.logout();
  }

  const closeDialogTimer = () => {
    if(localStorage.getItem('token')) {
      clearAllInterVal(); 
      checkIdleTime();
      setOpenToolModal(false);
      setTimer('');
    } else {
      _onIdle('Clicked on signed in');
    }
  }

  const signOut = () => {
    closeDialogTimer();
    tologout();
    auth0Service.logout();
  }
    return(
      <Dialog 
            aria-labelledby="customized-dialog-title" open={openToolbarModal}
            disableBackdropClick disableEscapeKeyDown
            PaperProps={{
              style: {
                backgroundColor: platformHrThemeColor
              },
            }}
        >
        <DialogContent>
              <div style={{textAlign:'center', paddingBottom:'24px', color:'#ffffff'}}><Icon style={{fontSize:'48px'}}>timer</Icon></div>
              <div style={{textAlign:'center', paddingBottom:'19px', fontSize:'22px', color:'#ffffff'}}>Are you done ?</div>
              <div style={{textAlign:'center', fontSize:'12px', color:'#ffffff'}}>For your security, we'll automatically sign you out shortly.</div>
              <div style={{textAlign:'center', paddingTop:'15px', fontSize:'20px', paddingBottom:'12px', color:'#ffffff'}}>{timer}</div>
              <div style={{textAlign:'center'}}>
                <Button autoFocus color="success" style={{textTransform:'none', border:'1px solid gray', color:'#ffffff'}} onClick={()=> message('signedin', true)}>
                  Stay signed in
                </Button>
              </div>
              <div style={{textAlign:'center'}}>
                <Button autoFocus color="success" style={{textTransform:'none', color:'#ffffff'}} onClick={()=> message('logout', true)}>
                  Sign me out
                </Button>
            </div>
        </DialogContent>
      </Dialog>
  )
}


class MainToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      right: false,
      userMenu: null,
      data: null,
      userdata: "",
      agentNotificationNew: [],
      agentNotificationOld: [],
      bnkRecNotification: [], ewaRecNotification: [],
      notificationCount: 0,
      notification: null, timer: null, remainTime: 2,
      organizations: [], openToolbarModal: false,
      tenantId: null, mainToolInterValId: null,
      orgName: '', outIntervalId: null,
      open: false, showHelpIcon: {}, toggleDrawerHelp: false,
      idTenant: 0, alertFileDialog: false,
      exportNotificationData: [], timeout: null,
      exportEmpFundsNotificationData: []
    };
  }

  getIdleTimeout = () => {
    axios.get("/api/rec/items/agent/idleTimeout").then(res => {
      const {idleLogOut, remainingTime} = res.data.data;
      this.setState({
        timeout: 60000 * (idleLogOut ? idleLogOut : 10),
        remainTime: (remainingTime ? remainingTime : 2)
      });
    });
  };

  tologout = () => {
    localStorage.removeItem("token");
    let _path = redirectlogin;

    window.location.href = _path;
  };

  toggleDrawer = (side, open) => () => {
    const {showHelpIcon : {bankRec, empFund}} = this.state;
    this.setState({
      [side]: open
    },
    () => {
      if(bankRec || empFund) {
        if(bankRec) {
          this.getFileActivityLogs(true);
          this.getExportReportNotification();
        }
        if(empFund) {
          this.getEwaActivityLogs(true);
          this.getEmpFundsExportReportNotification();
        }
      }
      else {
        axios.get("/api/rec/items/agent/notification/update").then(res => {
          this.getNotifications();
        });
      }
      
    });
  }

  toggleDrawerClose = (side, open) => () => {
    const {showHelpIcon : {bankRec:closeBnkDrawer, empFund: closeEwaEmpFund}} = this.state;
    this.setState(
      {
        [side]: open
      },
      () => {
        if(closeBnkDrawer && closeEwaEmpFund) {
          this.getEwaActivityLogs(true); this.getFileActivityLogs(true); 
        }
        else if(closeBnkDrawer)
          this.getFileActivityLogs(true);
        else if(closeEwaEmpFund)
          this.getEwaActivityLogs(true);
        else {
          axios.get("/api/rec/items/agent/notification/update").then(res => {
            this.getNotifications();
          });
        }
      }
    );
  };

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget });
  };

  userMenuClose = () => {
    this.setState({ userMenu: null });
  };
  setlocal = name => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  changeDefaultOrganization(id) {
    const {isSwitchingTenantImport} = this.props
    if (isSwitchingTenantImport) {
      this.setState({alertFileDialog: true, idTenant: id})
    } else {
      this.props.chenageDefaultOrg(id);
    }
  }

  handleIsFileProcessNextScreen = () => {
    this.setState({alertFileDialog: false});
    this.props.chenageDefaultOrg(this.state.idTenant);
  }

  handleIsFileProcess = () => {
    this.setState({alertFileDialog: false});
  }

  showDocsLinks = showHelpIcon => {
    this.setState({showHelpIcon});
  }

  ewaSocketOn = () => {
    socket.off("showEwaStatus").on("showEwaStatus", async ewaStat => {
      const { showEwaCnt } = ewaStat;
      if (showEwaCnt && showEwaCnt === 'Complete') {
        let ewaCountNotify = this.state.notificationCount + 1;
        this.setState({ notificationCount: ewaCountNotify });
      }
      await this.getEwaActivityLogs();
    });
  }
  /**
   * Execute when component mount
   */
  async componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    socket.off("notifyBlobStorageQueue").on("notifyBlobStorageQueue", data => {
      this.setState({notificationCount: this.state.notificationCount + 1 });
    })
    socket.emit("MessageFromPlatformHR", {
      token: localStorage.getItem("token")
    });
    socket.off("updateFromAgent").on("updateFromAgent", async data => {
      var temp = this.state.agentNotificationNew;
      temp.push(data);
      let count = this.state.notificationCount + 1;
      this.setState({ agentNotificationNew: temp, notificationCount: count });
      if (prevState !== data) {
        toast(data);
        prevState = data;
      }
    });
    socket.off("showStatus").on("showStatus", async data => {
      const {showCnt} = data;
      if(showCnt && showCnt === 'Complete') {
        let countNotify = this.state.notificationCount + 1;
        this.setState({notificationCount: countNotify });
      }
        await this.getFileActivityLogs();
    });
    this.ewaSocketOn();
    this.redirectFm();
    /**
     * Function to get log in user information.
     */
    try {
    const res = await axios.get("/api/users/me");
      this.setState(
        {
          userdata: res.data.data.userDetails,
          tenantId: res.data.data.setting.defaultOrganization
        },
        () => {
          socket.on("connect", function() {
            let eventName =
              res.data.data.userDetails._id +
              "_" +
              res.data.data.setting.defaultOrganization;
            socket.emit("room", eventName);
          });
        }
      );
    }
    catch(error) {
      console.log(error);
    }
    this.props.getOrganizations();
    //this.getFileActivityLogs();
    //this.getNotifications();
    // this.checkIdleTime();
  }

  /** START: Bug 5152: Data crossing tenants */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = () => {
    // Clear sessionStorage values
    sessionStorage.removeItem('sessionInfo');
  };
/** END: Bug 5152: Data crossing tenants */

  redirectFm = () => {
    socket.on('redirectCFM', data => {
      const {roles, orgId, id:socket} = data;
      sessionStorage.setItem("sessionInfo", orgId);
      window.socketInfo = socket;
      const {setOrgRoleId, setBankRecId} = this.props;
      if(roles.length === 1 && roles[0] === 110) {
        history.push('/app/management/dashboard');
        setOrgRoleId(null);
        setBankRecId(null);
      }
      else if(roles.length === 1 && roles[0] === 126) {
        history.push('/app/management/dashboard');
        setOrgRoleId({orgid: orgId, role: roles[0]});
        setBankRecId(null);
      } else if(roles.length === 1 && 
        (roles[0] === 128 || roles[0] === 129 || roles[0] === 8 || roles[0] === 131 || roles[0] === 132 || roles[0] === 133)) {
        if(roles[0] !== 8) { history.push('/app/bankRec/dashboard');}
        if(roles[0] === 129) {
          setBankRecId({bankRec: 'icons', empFund: null});
          setOrgRoleId({defaultOrg: orgId}); this.showDocsLinks({bankRec: true, clientFunds: false, empFund: false});
        } else if(roles[0] === 131) {
          setOrgRoleId({orgid: orgId, role: roles[0], defaultOrg: orgId});
          this.showDocsLinks({bankRec: true, clientFunds: false, empFund: false}); setBankRecId({empFund: null, bankRec: true});
        }
        else if(roles[0] === 128 || roles[0] === 132) {
          if(roles[0] === 132) {
            history.push('/app/bankRec/panel');
          }
          setOrgRoleId({defaultOrg: orgId});
          setBankRecId({bankRec: roles[0] === 128 ? true : 'icons', empFund: null}); this.showDocsLinks({bankRec: true, clientFunds: false, empFund: false});
        } else if(roles[0] === 133) {
            history.push('/app/bankRec/workflow'); 
            setOrgRoleId({defaultOrg: orgId}); setBankRecId(null); this.showDocsLinks({bankRec: true, clientFunds: false, empFund: false});
        }
        else {
          setOrgRoleId({role: roles[0], defaultOrg: orgId});
          setBankRecId({empFund: true, bankRec: true}); this.showDocsLinks({bankRec: true, clientFunds: false, empFund: true});
        }
      } else if (roles.length === 1 && [135, 136, 137, 138, 139].indexOf(roles[0]) !== -1) {
        const roleId = roles[0];
        this.employeFundRoleOrg({roleId, orgId});
      }
       else {setOrgRoleId({defaultOrg: orgId}); setBankRecId(null);
        
        this.showDocsLinks({bankRec: false, clientFunds: true});
      }
      this.getIdleTimeout();
    });
  }

  employeFundRoleOrg = ({ roleId, orgId }) => {
    const {setOrgRoleId, setBankRecId} = this.props;
    if (roleId !== 8) history.push('/app/ewa/dashboard')
    if (roleId === 136) {
      setBankRecId({empFund: 'icons', bankRec: null});
      setOrgRoleId({ defaultOrg: orgId }); this.showDocsLinks({ bankRec: false, clientFunds: false, empFund: true });
    } else if (roleId === 137) {
      setOrgRoleId({ orgid: orgId, role: roleId, defaultOrg: orgId });
      this.showDocsLinks({ bankRec: false, clientFunds: false, empFund: true  }); setBankRecId({empFund: true, bankRec: null});
    }
    else if (roleId === 135 || roleId === 138) {
      if (roleId === 138) history.push('/app/ewa/panel');
      setOrgRoleId({ defaultOrg: orgId });
      setBankRecId({empFund:roleId === 135 ? true : 'icons', bankRec: null}); this.showDocsLinks({ bankRec: false, clientFunds: false, empFund: true  });
    } else if (roleId === 139) {
      history.push('/app/ewa/ledgerRec');
      setOrgRoleId({ defaultOrg: orgId }); setBankRecId(null); this.showDocsLinks({ bankRec: false, clientFunds: false, empFund: true  });
    }
    else {
      setOrgRoleId({ role: roleId, defaultOrg: orgId }); this.showDocsLinks({ bankRec: true, clientFunds: false, empFund: true });
      setBankRecId({empFund: true, bankRec: true}); 
    }
  }

  /**
   * Function to get notifications
   */
  getNotifications = () => {
    axios.get("/api/rec/items/agent/notification").then(res => {
      let messagesNew = [];
      let messagesOld = [];
      let count = 0;
      res.data.data.forEach(data => {
        let AppTimezone = momentTimezone
          .utc(data.updatedAt)
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A z");

        if (!data.dirty) {
          count++;
          messagesNew.push(AppTimezone + "\n" + data.details);
        } else {
          messagesOld.push(AppTimezone + "\n" + data.details);
        }
      });
      // messagesOld = messagesOld.reverse();
      messagesOld.reverse();
      this.setState({
        agentNotificationNew: messagesNew,
        agentNotificationOld: [...this.state.agentNotificationOld, ...messagesOld],
        notificationCount: count
      });
    }).catch(noterr=>{
      console.error(noterr);
    });
  };

  getEwaActivityLogs = async chkEwaCount => {
    const objEwaNot = chkEwaCount ? { notificationCount: 0 } : {};
    const { ewaRecNotification } = this.state;
    try {
      const fileEwaLogres = await axios.get("/api/employeeFunds/items/fileActivityLogs")
      let oldEwaMessage = [];
      fileEwaLogres.data.data.forEach(objEwaData => {
        oldEwaMessage.push({
          fileName: objEwaData.fileName, msg: objEwaData.msg, error: objEwaData.errorFlag, objId: objEwaData._id,
          createdAt: objEwaData.createdAt
        });
      });
      if (ewaRecNotification.length > 0)
        this.setState({ ewaRecNotification: [] })

      oldEwaMessage = [...oldEwaMessage.filter(s => s.msg.indexOf('In Progress') !== -1), ...oldEwaMessage.filter(s => s.msg.indexOf('In Progress') === -1)];
      if (!chkEwaCount && oldEwaMessage.length && !oldEwaMessage.some(r => r.msg && r.msg.indexOf('In Progress') !== -1))
        store.dispatch({ type: "IS_SWITCHING_TENANT", payload: false });

      this.setState({
        ewaRecNotification: oldEwaMessage,
        ...objEwaNot
      });
    }
    catch (ewaNotError) {
      console.error(ewaNotError);
    }
  }

  //get File activity logs

  getFileActivityLogs = async (chkCount) => {
    const objNot = chkCount ? {notificationCount: 0} : {};
    try {
    const fileLogres = await axios.get("/api/bankRec/items/fileActivityLogs")
      let oldMessage = [];
      fileLogres.data.data.forEach(objData => {
        oldMessage.push({
          fileName: objData.fileName, msg:objData.msg, error: objData.errorFlag, objId: objData._id,
          createdAt: objData.createdAt
        });
      });
      if(this.state.bnkRecNotification.length > 0) {
        this.setState({
          bnkRecNotification: []
        })
      }
      oldMessage = [...oldMessage.filter(s=>s.msg.indexOf('In Progress')!==-1), ...oldMessage.filter(s=>s.msg.indexOf('In Progress')===-1)];
      if(!chkCount && oldMessage.length && !oldMessage.some(r=>r.msg && r.msg.indexOf('In Progress')!==-1)) {
        store.dispatch({ type: "IS_SWITCHING_TENANT", payload: false});
      }
      this.setState({
        bnkRecNotification: oldMessage,
        ...objNot
      });
    }
    catch(error){
      console.error(error);
    }
  }

  //get export report 

  getExportReportNotification = async() => {
    try {
      const exportNotificationData =[];
      const exportNotification = await axios.get("/api/bankRec/report/exportNotification");
      exportNotification.data.data.map((obj) => {
          exportNotificationData.push({_id: obj._id, createdAt: obj.createdAt, blobStorageURL: obj.blobStorageURL, reportName: obj.reportName})
      })
      this.setState({exportNotificationData})
    } catch (error) {
      console.log('ExportReportNotification', error);
    }
  }

  getEmpFundsExportReportNotification = async() => {
    try {
      const exportEmpFundsNotificationData =[];
      const exportEmpFundsNotification = await axios.get("/api/employeeFunds/report/exportNotification");
      exportEmpFundsNotification.data.data.map((obj) => {
          exportEmpFundsNotificationData.push({_id: obj._id, createdAt: obj.createdAt, blobStorageURL: obj.blobStorageURL, reportName: obj.reportName})
      })
      this.setState({exportEmpFundsNotificationData})
    } catch (error) {
      console.log('ExportEmpFundsReportNotification', error);
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps ", nextProps)
    const {organizations} = nextProps;
    const {tenantId} = this.state;
    if (organizations) {
      this.setState({ 
        organizations: organizations,
        orgName:  organizations.length > 0 && tenantId ? organizations.find(rec =>rec._id === tenantId).coName : ''
      });
    }
  }

  openProfile = () => {
    this.setState({
      open: true,
      userMenu: null
    });
  }

  closeProfile = () => {
    this.setState({open: false});
  }

  toggleHelpDrawer = (help, val) => {
    this.setState({[help]: val});
  }

  downloadTemplateFile = async () => {
    try {
      const template = await axios.get(`/api/bankRec/getTemplateFile`);
      if(template.data && template.data.data) {
        const blob = b64ToBlob(template.data.data, "application/zip");
        fileSaver.saveAs(blob, `Template.zip`);
      }
    }
    catch(errDownloadTemplate) {
      console.error(errDownloadTemplate);
    }
  }

  downloadEmpFundsTemplateFile = async () => {
    try {
      const empFundTemplate = await axios.get(`/api/employeeFunds/getTemplateFile`);
      if(empFundTemplate.data && empFundTemplate.data.data) {
        const empFundBlob = b64ToBlob(empFundTemplate.data.data, "application/zip");
        fileSaver.saveAs(empFundBlob, `EmployerFundTemplate.zip`);
      }
    }
    catch(errEmpFundTemplate) {
      console.error(errEmpFundTemplate);
    }
  }

  supportSubHeader = () => <ListSubheader component="div">Help And Support</ListSubheader>

  emailSupport = () => {
    const { classes: emailClasses } = this.props;
    return (
      <ListItem>
        <a target="_blank" href={emailSupport} className={emailClasses.docsLink}>
          <ListItemText primary="E-mail Support" style={{ cursor: 'pointer' }} />
        </a>
      </ListItem>
    )
  }

  empFundReconciliationHelp = () => {
    const {classes: empFundHelpClass} = this.props;
    return (
      <>
        {this.supportSubHeader()}
        {empFundHelp && empFundHelp.trim() && <ListItem>
          <a target="_blank" href={empFundHelp} className={empFundHelpClass.docsLink}>
            <ListItemText primary="Finlee Documentation" />
          </a>
        </ListItem>}
        <ListItem>
          <ListItemText primary="Import File Templates" onClick={() => this.downloadEmpFundsTemplateFile()} />
        </ListItem>
        {this.emailSupport()}
      </>
    )
  }

  showSwipeableHelpDrawer = () => {
    const {toggleDrawerHelp, showHelpIcon : {bankRec, clientFunds, empFund}} = this.state;
    const { classes } = this.props;
      return(
        <>
        {(bankRec || clientFunds || empFund) && 
          <Tooltip title="Help And Support" disableFocusListener={true}>
          <IconButton
             onClick={evt=>this.toggleHelpDrawer("toggleDrawerHelp", true)}
             className={classes.cust} style={{position:'relative', top:'3px'}}
           >
             <Icon>help_outline</Icon>
           </IconButton>
           </Tooltip>
        }
        <SwipeableDrawer
            style={{ height: "10px" }} open={toggleDrawerHelp}
            anchor="right" 
            // title="Help And Support"
            onClose={() => this.toggleHelpDrawer("toggleDrawerHelp", false)}
            onOpen={() => this.toggleHelpDrawer("toggleDrawerHelp", true)}
          >
            <div
              tabIndex={0} role="button"
              onClick={() => this.toggleHelpDrawer("toggleDrawerHelp", false)}
              onKeyDown={() => this.toggleHelpDrawer("toggleDrawerHelp", false)}
            >
              <List>
              {bankRec && 
              <>
                  {this.supportSubHeader()}
                  {bankRecHelp && bankRecHelp.trim() && <ListItem>
                    <a target="_blank" href={bankRecHelp} className={classes.docsLink}>
                      <ListItemText primary="Finlee Documentation"/>
                    </a>
                  </ListItem>}
                  <ListItem>
                      <ListItemText primary="Import File Templates" onClick={()=>this.downloadTemplateFile()} />
                  </ListItem>
                  {this.emailSupport()}
              </>
              }
              {empFund && this.empFundReconciliationHelp()}
              {clientFunds && clientFundsHelp && clientFundsHelp.trim() &&
              <>
                {this.supportSubHeader()}
                <ListItem>
                <a target="_blank" href={clientFundsHelp} className={classes.docsLink}>
                  <ListItemText style={{cursor:'pointer'}} primary="Finlee Documentation"/>
                </a>
              </ListItem>
              {this.emailSupport()} 
              </>
              }
            </List>
            </div>
          </SwipeableDrawer>
          </>
      )
  }

  downloadURI = async uri => {
    try {
      let extn = uri.split('.')[1];
      const downloadTemplateFile = await axios.post("/api/bankRec/report/downloadTemplateFile",{uri, extn});
      if(extn === 'pdf') {
        const arrayBuffer = new Uint8Array(downloadTemplateFile.data.data.buffer.data);
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        fileSaver.saveAs(blob, uri)
      } else if (extn === 'csv') {
        const csvLedgCustBankBlob = new Blob([downloadTemplateFile.data.data], { type: 'text/csv;charset=utf-8' });
        saveFile(csvLedgCustBankBlob, uri.split('.')[0] + `.csv`);
      }
    } catch (error) {
      console.log('ExportReportNotification', error);
    }
  }

  handleErrorMsg = (code, fileName, createdAt) => {
    const {showHelpIcon : {empFund: tmpEmpFund}} = this.state;
    history.push({
      pathname: tmpEmpFund ? `/app/ewa/ErrorMessage/${code}`: `/app/bankRec/ErrorMessage/${code}`,
      state: { 
        fileName, showErrorFlag: true, createdAt,
        msg: 'File Imported completed with transaction warnings / errors'
      }
    });
  }

  viewActivityLogs = () => {
    const {showHelpIcon : {empFund}} = this.state;
    history.push({
      pathname: empFund ? `/app/ewa/FileImportActivity` : `/app/bankRec/FileImportActivity`
    });
  }

  bankRecSideList = () => {
    const {classes} = this.props;
    const { bnkRecNotification, exportNotificationData, ewaRecNotification, exportEmpFundsNotificationData} = this.state;
    const notificationArray = [...bnkRecNotification, ...exportNotificationData, ...ewaRecNotification, ...exportEmpFundsNotificationData];
    const progressBar = bnkRecNotification.some(r => r.msg.indexOf('In Progress') !== -1) || ewaRecNotification.some(r => r.msg.indexOf('In Progress') !== -1) ? true : false;
    return(
      <div className={classes.list} style={{width:450}}>
        <List divider={true}>
          {notificationArray.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt))
          .map(s=>({...s, fileTimeZone:momentTimezone.utc(s.createdAt).tz(getCurrentTimeZone()).format("MM/DD/YYYY hh:mm A z")}))
          .map(({msg, fileName, fileTimeZone, blobStorageURL, error, objId, createdAt, reportName}, bnkindex) => {
            if(!blobStorageURL) {
              let go = new RegExp('records imported', "gi").test(msg) || new RegExp('record imported', "gi").test(msg) ? msg.split(' '): msg;
              if(Array.isArray(go)) {
                go = (<><b>{go[0]}</b>{` ${go[1]} ${go[2]}`}</>);
              }
              const seeActivityLog = (
                <ListItem
                  button={true} key={`${fileTimeZone}${bnkindex}activity`} style={{ textAlign:'center', backgroundColor: "#FBF6F5" }}
                >
                  <ListItemText
                    primary={<span style={{textDecoration:'underline', color:'#0000FF'}} onClick={()=>this.viewActivityLogs()}>More Events in Activity Log</span>}
                  />
                </ListItem>
              );
              const fileslist = (
              <div>
                <ListItem
                  button={true} key={`${fileTimeZone}${bnkindex}`} style={{ backgroundColor: "#FBF6F5" }} divider={true}
                >
                  <ListItemText
                    primary={
                    progressBar && new RegExp('In Progress', "gi").test(msg) ? <><div class="dot-flashing"/><>{fileTimeZone}<br/><span style={{wordWrap: 'break-word'}}>{fileName}</span><br/>{msg}</></>:
                    new RegExp('records imported', "gi").test(msg) || new RegExp('record imported', "gi").test(msg) 
                    || new RegExp('records updated', "gi").test(msg) ?
                    <>
                    {error ? <ErrorIcon style={{position:'relative', top:'5px', right:'10px', color: '#FFA500'}}/>:
                    <CheckCircle style={{position:'relative', top:'5px', right:'10px', color: platformHrThemeColor}}/>}
                    <>{fileTimeZone}<br/>
                    <span style={{wordWrap: 'break-word'}}>{fileName}</span><br/>{go}<br/>{error ? 
                    <span style={{textDecoration:'underline', color:'#0000FF'}} onClick={()=>this.handleErrorMsg(objId, fileName, createdAt)}>Warnings / Errors</span>:''}</></>:
                    new RegExp('already imported', "gi").test(msg) || new RegExp('Error', "gi").test(msg) || new RegExp('Invalid', "gi").test(msg) ?
                    <><ErrorIcon style={{position:'relative', top:'5px', right:'10px', color: "#FF0000" }}/><>{fileTimeZone}<br/><span style={{wordWrap: 'break-word'}}>{fileName}</span><br/>{msg}</></>:
                    <>{fileTimeZone}<br/><span style={{wordWrap: 'break-word'}}>{fileName && <><br/></>}</span>{msg}</>}
                    key={`${fileTimeZone}${bnkindex}`} style={{ padding: "0px" }}
                  />
                </ListItem>
                <Divider variant="inset" component="li"  />
              </div>);
              if(bnkindex === 0) {
                return (<>
                  {seeActivityLog}{fileslist}
                </>)
              }
              return fileslist;
            } else {
              return(
                <div>
                  <ListItem>
                    <ListItemText
                      primary={
                        <>{fileTimeZone}<br/><span><b>{reportName} report is ready to</b></span> <a download style={{color: 'blue', textDecoration: 'underline'}} onClick={() => this.downloadURI(blobStorageURL)}>download</a></>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li"  />
                </div>
              )
            }
        })}
        </List>
        <Divider />
      </div>
    );
  }

  render() {
    const { classes, toggleQuickPanel, user, logout } = this.props;
    const {orgName, timeout, remainTime} = this.state;
    const { userMenu, userdata, open, agentNotificationOld, bnkRecNotification, ewaRecNotification, showHelpIcon : {bankRec, empFund}} = this.state;
    const linearProgress = bnkRecNotification.some(r=>r.msg.indexOf('In Progress')!==-1) || ewaRecNotification.some(r=>r.msg.indexOf('In Progress')!==-1) ? true : false;
    const sideList = (
      <div className={classes.list}>
        <List divider={true}>
          {this.state.agentNotificationNew.map((text, index) => (
            <div>
              <ListItem button={true} key={index} divider={true}>
                <ListItemText
                  primary={text}
                  key={index}
                  style={{ padding: "0px" }}
                />
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
          {agentNotificationOld.map((text, index) => (
            <div>
            <ListItem
              button={true}
              key={index}
              style={{ backgroundColor: "#FBF6F5" }}
              divider={true}
            >
              <ListItemText
                primary={text}
                key={index}
                style={{ padding: "0px" }}
              />
            </ListItem>
            <Divider component="li" variant="inset" />
          </div>
          ))}
        </List>
        <Divider />
      </div>
    );

    return (
      <div className={classNames(classes.root, "flex flex-row")}>
        {timeout &&
        <Timer
          timeout={timeout}
          remainTime={remainTime}
          tologout={this.tologout}
        />}
        <ToastContainer
          position="top-right"
          type={toast.TYPE.ERROR}
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
        <div className="flex flex-1">{/* <FuseShortcuts/>  */}</div>

        <div className="flex">
          {this.showSwipeableHelpDrawer()}
          <div className={classes.separator} />
        
          <Tooltip title="Notifications" disableFocusListener={true}>
          <IconButton
         
            onClick={this.toggleDrawer("right", true)}
            className={classes.cust}
          >
            <Badge
              className={classes.padding}
              max={99}
              badgeContent={this.state.notificationCount}
              showZero
              invisible={this.state.notificationCount === 0 ? true : false}
              color="secondary"
            >
          
                <Icon style={(bankRec || empFund) && linearProgress ? {height:'28px'}: {}}>
                  notifications
                  {(bankRec || empFund) && linearProgress && <LinearProgress color="secondary" className={classes.progress}/>}
                </Icon>
             
            </Badge>
          </IconButton>
          </Tooltip>
          <SwipeableDrawer
            style={{ height: "10px" }}
            anchor="right"
            open={this.state.right}
            onClose={this.toggleDrawerClose("right", false)}
            onOpen={this.toggleDrawer("right", true)}
            // title="Notifications"
          >
            {/* <IconButton onClick={this.toggleDrawer('right', false)} className={classes.whitecol}> */}
            <Typography
              component="span"
              style={{
                fontSize: "20px",
                padding: "10px",
                backgroundColor: "black",
                color: "white"
              }}
            >
              Notifications
              <Icon
                style={{ marginLeft: "100px", display:'inline', MozPaddingEnd: '12px', float:'right'}}
                onClick={this.toggleDrawerClose("right", false)}
                className={classes.whitecol}
              >
                close
              </Icon>
            </Typography>
            {/* </IconButton> */}

            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawerClose("right", false)}
              onKeyDown={this.toggleDrawerClose("right", false)}
            >
              {bankRec || empFund ? this.bankRecSideList() : sideList}
            </div>
          </SwipeableDrawer>

          <div className={classes.separator} />
          <Tooltip title="Tasks" disableFocusListener={true}>
            <IconButton
              className="w-64 h-64"
              onClick={() => toggleQuickPanel(true)}
            >
              <Icon className={classes.cust}>format_list_bulleted</Icon>
            </IconButton>
          </Tooltip>
          <FuseAnimate delay={600}>
            <Button className="h-64" onClick={this.userMenuClick}>
             {userdata.name && orgName && <div className="hidden md:flex flex-col ml-12 items-start">
                <Typography
                  component="span"
                  className={classes.cust}
                  style={{ paddingRight: "10px", fontSize: '1.9rem' }} //previously it was 1.4 rem
                >
                  {userdata.name}
                </Typography>
                <span style={{textTransform:'none', color:'#8b8b8b', letterSpacing:'1.6px'}}>{orgName}</span>
              </div>}
              {userdata.profilePicture ? (
                <Avatar
                  className=""
                  alt="user photo"
                  src={userdata.profilePicture}
                />
              ) : (
                ""
              )}
              <Icon className={classes.cust} variant="action">
                more_vert
              </Icon>
            </Button>
          </FuseAnimate>

          <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={this.userMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            classes={{
              paper: "py-8"
            }}
          >
            {user.role === "guest" ? (
              <React.Fragment>
                {this.state.organizations.map(text => (
                  <MenuItem
                    selected={this.state.tenantId === text._id ? true : false}
                  >
                    <ListItem>
                      <ListItemText
                        className="pl-0"
                        primary={text.coName}
                        onClick={() => this.changeDefaultOrganization(text._id)}
                      />
                    </ListItem>
                  </MenuItem>
                ))}
                <MenuItem onClick={this.openProfile}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon/>
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="Profile" />
                </MenuItem>
                <MenuItem onClick={this.tologout}>
                  <ListItemIcon>
                    <Icon>lock</Icon>
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="Logout" />
                </MenuItem>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <MenuItem
                  component={Link}
                  to="/pages/profile"
                  onClick={this.userMenuClose}
                >
                  <ListItemIcon>
                    <Icon>account_circle</Icon>
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="My Profile" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    logout();
                    this.userMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="Logout" />
                </MenuItem>
              </React.Fragment>
            )}
          </Popover>
        </div>
        {open && <UpdateUserProfile open={open} close={this.closeProfile} id={userdata._id}/>}
        {this.state.alertFileDialog && <AlertDialogScreenLeave 
          alertFileDialog={this.state.alertFileDialog} dontSwitch={true}
          handleIsFileProcessNextScreen={this.handleIsFileProcessNextScreen}
          handleIsFileProcess={this.handleIsFileProcess}/>}
      </div>
    );
  }
}

let mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleQuickPanel: quickPanelActions.toggleQuickPanel,
      logout: authActions.logoutUser,
      openChatPanel: chatPanelActions.openChatPanel,
      getOrganizations: authActions.getOrganizations,
      chenageDefaultOrg: authActions.chenageDefaultOrg,
      setOrgRoleId: authActions.setOrgRoleId,
      setBankRecId: quickPanelActions.setBankRecId
    },
    dispatch
  );
}

let mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
    organizations: auth.user.organizations,
    orgroleId: auth.user.disorgId,
    isSwitchingTenantImport: auth.fileImport.IS_SWITCHING_TENANT
  };
}

MainToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(MainToolbar)
);