import React from 'react';
import { 
    withStyles, Grid, Icon, Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip,
    AppBar, Toolbar, IconButton, TextField, InputAdornment, Radio, MenuItem
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios'; import Autocomplete from '../content/apps/employeeFunds/employeeFundsPanel/Autocomplete';
import moment from 'moment';
import TransferMoneyClientDialog from '../content/apps/employeeFundsTasks/employerList/ClientDialog';
import {platformHrThemeColor, disableHtmlElement, snackBarComponent} from '../../genericFunctions'; 
import CryptoJSTransfer from "crypto-js";

const useStyles = () => ({
    coName: {
        width: '244px', border:'1px solid lightgray', height: '50px', padding: '6px'
    },
    status: {
      border:'1px solid lightgray', height: '50px', padding: '6px', width: '226px'
    },
    paperFullWidth: {overflowY: 'visible'}
})

class TransferMoney extends React.Component {

    state = {
      coName: '',
      transactionDate: moment(new Date()).format("YYYY-MM-DD"), bnkDetails: '',
       amount: 0, objid: null, openDialogTransfer: false, fromAmt: 0,
      account: '', fromAccntSel: '', toAccntSel: '', cleared: null, listacct: [],
      recType: '',  samtChk: false, transCodeList: [], fromEmpName: '',
      bnkTransfer: 'bnkTransfer', nonBnkTransfer: null, bankingTransferTo: [{accountName: '', employer: '', employerName: '', partner: '', amount: 0.00}],
      openDialogTransferClients: false, chkAddTransferClient: 'add', compTransferId: '', compTransferName: '', compTransferFein: '',
      compTransferStatus: 'Active', clientTransferList: [], fromEmployer: '', fromEmployerName: '', fromPartner:'', fromPartnerName: '',
      nonBankingTransferTo : [{employer: '', employerName: '', partner: '', amount: 0.00}],
      fromNonBankEmployer: '', fromAccntNonBnking: '', fromNonBnkAmt: 0, removeAddAmt: false,
      clientTransfSnack: false, clientTransfSnckmessage: '', frmNonBnkCustomer: '', frmBnkCustomer: '', disableTransfer: false
    }

    async componentDidMount() {
        await this.ledgerParseCode();
        await this.ledgerConfigDetails();
    }

    ledgerConfigDetails = async () => {
      try {
      const ledresq = await axios.get('api/rec/tenant/ledgerConfig');
      if(ledresq.status === 200) {
          this.setState(()=>({configDetails: ledresq.data.data.configDetails}));
      }
      }
      catch(lederrq) {
          console.error(lederrq);
      }
   }

    ledgerParseCode = async () => {
      try {
          const res = await axios.post(`/api/bankRec/items/agent/ledgerParseCode`,{
            select:{accounts: 1, transactionCodes: 1}
          });
          if(res.data.data && res.data.data.length) {
            const {transactionCodes, accounts: listacct} = res.data.data[0];
            let transCodeList = transactionCodes ? transactionCodes: [];
            this.setState({transCodeList, listacct});
          }
      }
      catch(err) {
          console.error(err);
      }
  }

    handleClose = (close=false) => {
      const {closeTransferMoney} = this.props;
        this.setState({ 
          amount:0, openDialogTransfer: false,
          transactionDate: moment(new Date()).format("YYYY-MM-DD"),
          coName:'', fromAccntSel: '', toAccntSel: ''
        }, ()=>{
          closeTransferMoney();
        });
    }

    handleChangeManualAdd = evt => {
        this.setState({manualCheck: evt.target.checked});
    }

    mapKeyNames = key => {
      let coKey = key;
      switch(key) {
        case 'compId' :  coKey = 'compTransferId'; break;
        case 'compName' :  coKey = 'compTransferName'; break;
        case 'compFein' :  coKey = 'compTransferFein'; break;
        case 'compStatus' :  coKey = 'compTransferStatus'; break;
      }
      return coKey;
    }

    handleChange = (evt, keyFrom) => {
        const val = evt.target.value;
        const chk = this.mapKeyNames(keyFrom);
        if (chk && (chk !== 'transactionDate')) {
          this.setState({[chk]: chk === 'fromAmt' || chk === 'fromNonBnkAmt' ? Number(Number(val).toFixed(2)) : val, removeAddAmt: true},()=>{
            this.setState({removeAddAmt: false});
            if(chk === 'fromAccntNonBnking') {
              const {fromAccntNonBnking, listacct, nonBankingTransferTo} = this.state;
              const resetCustomer = listacct.some(n=>n.accountName.trim() === fromAccntNonBnking.trim() && n.ledger !== 'Tax');
              if(resetCustomer) {
                this.setState({
                  nonBankingTransferTo: nonBankingTransferTo.map(s=>({...s, customer: ''})),
                  frmNonBnkCustomer: ''
                })
              }
            }
            if(chk === 'fromAccntSel') {
              const {listacct, fromAccntSel} = this.state;
              const resetBnkCustomer = listacct.some(n=>n.accountName.trim() === fromAccntSel.trim() && n.ledger !== 'Tax');
              if(resetBnkCustomer) {
                this.setState({frmBnkCustomer: ''});
              }
            }
          });
        }
        if (chk && chk === "transactionDate") {
          let date = val, startYear = 1900, endYear = 2100;
          let currentYear = parseInt(date.substring(0, 4));
          if (currentYear >= startYear && currentYear <= endYear) 
              this.setState({transactionDate: val});
        }
    }

    genTransType = () => {
      const {transCodeList} = this.state;
      let fromTransType = '', toTransType = '';
      const fromObj = transCodeList.find(g=>g.transCode === '506');
      const toObj = transCodeList.find(g=>g.transCode === '206');
      if(fromObj) 
        fromTransType = fromObj.transDesc;
      if(toObj) 
        toTransType = toObj.transDesc;
      
      return {fromTransType, toTransType};
    }

    payLoadObj = () => {
      const {
        bankingTransferTo, bnkTransfer, fromAccntSel, fromAmt, fromEmployer, fromEmployerName, bnkDetails, transactionDate, fromAccntNonBnking, fromNonBnkAmt,
        fromNonBankEmployer, nonBankingTransferTo, frmNonBnkCustomer, frmBnkCustomer, fromPartner, fromPartnerName
      } = this.state;
      let hash = CryptoJSTransfer.algo.SHA256.create();

      let fromPayLoadObj = {}, finalPayLoad = [];
      const {fromTransType, toTransType} = this.genTransType();
      const {accountId, ledger, ledgerId} = this.genAccntLedgerInfo(bnkTransfer ? fromAccntSel: fromAccntNonBnking);
      if(bnkTransfer) {
        fromPayLoadObj = [{
          accountName: fromAccntSel, amount: -Math.abs(fromAmt.toFixed(2)), employer: fromEmployer, details: bnkDetails, transactionDate,
          accountId, ledger, ledgerId, transactionType: fromTransType, customer: frmBnkCustomer, employerName: fromEmployerName, partner: fromPartner,
          partnerName: fromPartnerName
        }];
        bankingTransferTo.forEach(py=>{
          py.amount = Math.abs(py.amount.toFixed(2));  py.transactionType = toTransType;
          py.details = bnkDetails; py.transactionDate = transactionDate;
        });
        finalPayLoad = [...finalPayLoad, ...bankingTransferTo, ...fromPayLoadObj];
        finalPayLoad.forEach(btr=> {const chunkBtrString = JSON.stringify(btr); hash.update(CryptoJSTransfer.enc.Utf8.parse(chunkBtrString));});
      } else {
        fromPayLoadObj = [{
          accountName: fromAccntNonBnking, amount: -Math.abs(fromNonBnkAmt.toFixed(2)), employer: fromNonBankEmployer, details: bnkDetails, transactionDate, partnerName: fromPartnerName,
          accountId, ledger, ledgerId, transactionType: fromTransType, nonbanking: true, customer: frmNonBnkCustomer, partner: fromPartner, employerName: fromEmployerName
        }];
        nonBankingTransferTo.forEach(py=>{
          py.amount = Math.abs(py.amount.toFixed(2));  py.transactionType = toTransType;
          py.details = bnkDetails; py.transactionDate = transactionDate; py.accountName = fromAccntNonBnking;
          py.accountId = accountId;  py.ledger = ledger; py.ledgerId = ledgerId; py.nonbanking = true;
        });
        finalPayLoad = [...finalPayLoad, ...nonBankingTransferTo, ...fromPayLoadObj];
        finalPayLoad.forEach(btr=> {const chunkNnBtrString = JSON.stringify(btr); hash.update(CryptoJSTransfer.enc.Utf8.parse(chunkNnBtrString));})
      }
      const hashedTData = hash.finalize().toString();
      return {finalPayLoad, hashedTData};
    }

    transferLedgerTransactions = async () => {
      const {nonBnkTransfer} = this.state;
        try {
          this.setState({disableTransfer: true});
          const {finalPayLoad, hashedTData: transferHash} = this.payLoadObj();
          await axios.post(`/api/employeeFunds/items/bankRecLedger/ewaTransferMoney`, {
            data: {
              payload: finalPayLoad, transferHash, nonBnkTransfer
            }
          });
          
          this.handleClose(true); this.setState({disableTransfer: false});
        }
        catch(errTransfer) {
          console.error(errTransfer); this.setState({disableTransfer: false});
        }
    }

    handleBankingTransfer = (evt, chk) => {
      const nullifyVal = chk === 'bnkTransfer' ? {
        bankingTransferTo: [{accountName: '', employer: '', amount: 0.00}], fromEmpName: '', fromPartner: '', fromNonBankEmployer: '', fromPartnerName: '',
        fromEmployer: '', fromAccntSel: '', fromAmt: 0, bnkDetails: '', removeAddAmt: false, frmBnkCustomer:'', frmNonBnkCustomer: '',
        fromEmployerName: ''
      } : {
        fromEmployerName: '', fromNonBnkAmt: 0, fromNonBankEmployer: '', removeAddAmt: false, fromEmpName: '', fromPartner: '', frmBnkCustomer: '',
        nonBankingTransferTo : [{employer: '', amount: 0.00}], fromAccntNonBnking: '', bnkDetails: '', frmNonBnkCustomer: '', fromPartnerName: ''
      };
      this.setState({
        bnkTransfer: chk === 'bnkTransfer' ? chk : null,
        nonBnkTransfer : chk === 'nonBnkTransfer' ? chk : null,
        ...nullifyVal
      });
    }

    addLedgerItem = () => {
        const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state;
        if(bnkTransfer) {
          bankingTransferTo.push({accountName: '', employer: '', amount: 0.00});
          this.setState({bankingTransferTo, removeAddAmt: false});
        } else {
          nonBankingTransferTo.push({employer: '', amount: 0.00});
          this.setState({nonBankingTransferTo, removeAddAmt: false});
        }
    }

    deleteLedgerItem = (evt, delIndex) => {
      const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state;
      if(bnkTransfer) {
        bankingTransferTo.splice(delIndex, 1);
        this.setState({bankingTransferTo, removeAddAmt: true});
      } else {
        nonBankingTransferTo.splice(delIndex, 1);
        this.setState({nonBankingTransferTo, removeAddAmt: true});
      }
    }

    onToCoSelect = onTo => {
      const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state;
      const {employer, partner ,employerName, empSearchText, obj, partnerName} = onTo;
      const {toInd} = obj || {};
      if(bnkTransfer && typeof toInd === 'number') {
        bankingTransferTo[toInd]['employer'] = employer;
        bankingTransferTo[toInd]['employerName'] = employerName;
        bankingTransferTo[toInd]['partner'] = partner;
        bankingTransferTo[toInd]['partnerName'] = partnerName;
        bankingTransferTo[toInd]['empName'] = empSearchText;
        this.setState({bankingTransferTo});
      } else {
        nonBankingTransferTo[toInd]['employer'] = employer;
        nonBankingTransferTo[toInd]['employerName'] = employerName;
        nonBankingTransferTo[toInd]['partner'] = partner;
        nonBankingTransferTo[toInd]['partnerName'] = partnerName;
        nonBankingTransferTo[toInd]['empName'] = empSearchText;
        this.setState({nonBankingTransferTo});
      }
    }

    onFromBnkTransferCoSelect = fmChk => {
      const {bnkTransfer, bankingTransferTo} = this.state;
      const {employer, partner ,employerName, empSearchText, partnerName} = fmChk;
      if(bnkTransfer) {
        bankingTransferTo[0]['employer'] = employer;
        bankingTransferTo[0]['employerName'] = employerName;
        bankingTransferTo[0]['partner'] = partner;
        bankingTransferTo[0]['partnerName'] = partnerName;
        bankingTransferTo[0]['empName'] = empSearchText;
        this.setState({
          fromEmployer : employer, fromEmployerName: employerName, bankingTransferTo, fromEmpName: empSearchText,
          fromPartner: partner, fromPartnerName: partnerName
        });
      } else {
        this.setState({
          fromNonBankEmployer : employer, fromEmpName: empSearchText,
          fromPartner: partner, fromEmployerName: employerName, fromPartnerName: partnerName
        });
      }
    }

    handleTransferClientClose = () => {
      this.setState({ 
          openDialogTransferClients: false,
          chkAddTransferClient: 'add',
          compTransferName: '',
          compTransferFein: '',
          compTransferStatus: 'Active', compTransferId: ''
      });
  }

  saveClientTransferData = async (impchk, evt) => {
    const {compTransferId, compTransferFein, compTransferName, compTransferStatus} = this.state;
    let transfObj = {};
    if(impchk && impchk === 'add') {
        transfObj = {
          coId: compTransferId.trim().toUpperCase(),
          coName: compTransferName.trim(),
          coFein: compTransferFein.trim(),
          coStatus: compTransferStatus, "edited" : false, "coEes" : "", "coType" : "Client", "coDba" : ""
      }
    }
    try {
        const transfRes = await axios.put('api/employeeFunds/items/addUpdateClient/client',{ pushInfo : transfObj }); 
        const updTransfResponse = transfRes.data.data;
        if(typeof updTransfResponse !== 'string' && Object.keys(updTransfResponse).length) {
            // await this.fetchTransferBankRecClients();
        } else {
            this.setState({
                clientTransfSnack: !Array.isArray(updTransfResponse) ? true: false,
                clientTransfSnckmessage: !Array.isArray(updTransfResponse) ? `${compTransferId.trim()} already exist in the system` : '',
            });
        }
        // const {clientCoName} = this.state;
        const finalCo = compTransferId  && compTransferId.trim() ? [].filter(p=>p.indexOf(compTransferId.trim())!==-1)[0] : '';
        this.setState({compTransferName: finalCo ? finalCo : ''});
        this.handleTransferClientClose();
    }
    catch(errSaveTransferClient) {
        console.error(errSaveTransferClient);
    }
  }

  openCliTransferDialog = (compTransferId, openDialogTransferClients) => {
    this.setState({compTransferId, openDialogTransferClients});
  }

  genAccntLedgerInfo = actBnkTransferVal => {
    const {configDetails, listacct} = this.state;
    let accnInfo = configDetails.filter(cfg=>cfg.bankAccounts.find(an=>an.name === actBnkTransferVal));
    const {accountId} = accnInfo[0]['bankAccounts'].find(j=>j.name === actBnkTransferVal);
    let ledgerId = accnInfo[0]['ledgers'][0]['ledgerId'];
    let ledger = listacct.filter(g=>g.accountName === actBnkTransferVal)[0]['ledger'];
    return {ledger, ledgerId, accountId};
  }

  onBnkTransferToAccount = (evt, ind, key) => {
    const {bankingTransferTo, bnkTransfer, nonBankingTransferTo} = this.state; let actBnkTransferVal = evt.target.value.trim();
    actBnkTransferVal = key === 'amount' ? Number(Number(actBnkTransferVal).toFixed(2)) : actBnkTransferVal;
    if(bnkTransfer) {
      bankingTransferTo[ind][key] = actBnkTransferVal;
      if(key !== 'amount' && key !== 'customer' && actBnkTransferVal) {
        const {ledger, ledgerId, accountId} = this.genAccntLedgerInfo(actBnkTransferVal);
        if(ledger !== 'Tax') {
          bankingTransferTo[ind]['customer'] = '';
        }
        bankingTransferTo[ind]['ledger'] = ledger; bankingTransferTo[ind]['accountId'] = accountId;
        bankingTransferTo[ind]['ledgerId'] = ledgerId; 
      } 
      this.setState({bankingTransferTo, removeAddAmt: true}, ()=>
      {this.setState({removeAddAmt: false});
      });
    } else {
      nonBankingTransferTo[ind][key] = actBnkTransferVal; 
      this.setState({nonBankingTransferTo, removeAddAmt: true}, ()=>
      {this.setState({removeAddAmt: false});});
    }
  }

  bankingTransferJSX = () => {
    const {
      fromAccntSel, fromEmpName, listacct, bankingTransferTo, fromAmt, fromEmployer, removeAddAmt, frmBnkCustomer
  } = this.state;
  const frmBnkAmtVal = removeAddAmt ? {value: Number(fromAmt).toFixed(2)} : {};
  const checkTaxBnkTransfAccnt = listacct.some(n=>n.accountName.trim() === fromAccntSel.trim() && n.ledger === 'Tax');
  const fromCoClass = {labelStyle:{position:'relative', bottom: fromEmployer ? '0px': '6px'},inputStyle: {height:'5rem'}};
    return(
      <>
          <Grid container spacing={3}>
              <Grid item xs={3}>
                  <TextField
                          value={fromAccntSel ? fromAccntSel : ''} onChange={evt =>this.handleChange(evt,'fromAccntSel')} select
                          variant="outlined" label={<span style={{position:'relative', bottom: fromAccntSel ? '0px' : '7px'}}>From Account</span>}
                          style={{marginTop:'10px', width:'65%'}}
                          name='fromAccount' InputProps={{ style: {height:'4.8rem' } }}
                  >
                          <MenuItem value={""}>From Account</MenuItem>
                          {
                          listacct && listacct.length > 0 && listacct.map(v=>v.accountName)
                          .sort((a,b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                          .map(acct =>
                              <MenuItem value={acct}>{acct}</MenuItem>
                          )
                      }
                  </TextField>
                </Grid>
                <Grid xs={1} item></Grid>
                <Grid item xs={4} style={{position:'relative', top:'10px'}}>
                  <Autocomplete
                      money={true} placeHoldCo="From Employer"
                      onSelectChange={this.onFromBnkTransferCoSelect}
                      openCliDialog = {this.openCliTransferDialog}
                      coName={fromEmpName} bankFromCo={true} autoCompleteClass={fromCoClass}
                    />
                </Grid>
                <Grid item xs={2} style={{position:'relative', left:'14px', top:'3px'}}>
                  <TextField
                        margin="dense" id="name" label="Amount" type="number"
                        variant="outlined" onBlur={evt =>this.handleChange(evt, 'fromAmt')}
                        {...frmBnkAmtVal}
                        defaultValue={fromAmt === 0 || fromAmt ? fromAmt.toFixed(2) : '' }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                          )
                        }}
                    />
                </Grid>
                {checkTaxBnkTransfAccnt && 
                  <Grid item xs={2} style={{position:'relative', left:'29px', top:'3px'}}>
                    <TextField
                            margin="dense" id="name" label="Customer" type="text"
                            variant="outlined"
                            value={frmBnkCustomer}
                            style={{width:'86%'}}
                            onChange={evt => this.handleChange(evt, 'frmBnkCustomer')}
                        />
                  </Grid>
                }
          </Grid>
          {
              bankingTransferTo.map((toacnt, toInd)=>{
                  const {accountName, employer, amount, customer, empName} = toacnt;
                  const bnkAmtVal = removeAddAmt ? {value: Number(amount).toFixed(2)} : {};
                  const arrayChecBnkAccnt = listacct.some(n=>n.accountName.trim() === accountName.trim() && n.ledger === 'Tax');
                  const toCoClass = {labelStyle:{position:'relative', bottom: employer ? '0px': '6px'},inputStyle: {height:'5rem'}};
                  return(
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                          <TextField
                                  value={accountName ? accountName : ''} onChange={evt =>this.onBnkTransferToAccount(evt, toInd, 'accountName')}
                                  variant="outlined" name='toAccount' InputProps={{ style: {height:'4.8rem' } }}
                                  select style={{marginTop:'14px', width:'65%'}} label={<span style={{position:'relative', bottom: accountName ? '0px' : '7px'}}>To Account</span>}
                          >
                                  <MenuItem value={""}>To Account</MenuItem>
                                  {
                                  listacct && listacct.length > 0 && listacct.map(v=>v.accountName)
                                  .sort((a,b) => (a.toLowerCase() > b.toLowerCase()) - (a.toLowerCase() < b.toLowerCase()))
                                  .map(acct =>
                                      <MenuItem value={acct}>{acct}</MenuItem>
                                  )
                              }
                          </TextField>
                        </Grid>
                        <Grid item xs={1} style={{position:'relative', left:'1%', top:'25px'}}>
                            {toInd !== 0 && <Tooltip title="Delete Ledger Item">
                              <Icon onClick={evt => this.deleteLedgerItem(evt, toInd)  } color="action" style={{ fontSize: '32px', cursor: 'pointer', fontWeight: 'bold' }} >delete_outline</Icon>
                            </Tooltip>}
                            {bankingTransferTo.length - 1 === toInd && <Tooltip title="Add Ledger Item">
                              <Icon onClick={event => this.addLedgerItem()  } color="action" style={{ fontSize: '32px', cursor: 'pointer', fontWeight: 'bold' }} >add_circle</Icon>
                            </Tooltip>}
                        </Grid>
                        <Grid item xs={4} style={{position:'relative', top:'15px'}}>
                          <Autocomplete
                              money={true} secondFlag={true}
                              onSelectChange={this.onToCoSelect} autoCompleteClass={toCoClass}
                              openCliDialog = {this.openCliTransferDialog}
                              coName={empName} toInd={toInd} placeHoldCo="To Employer"
                            />
                        </Grid>
                        <Grid item xs={2} style={{position:'relative', left:'14px', top:'8px'}}>
                          <TextField
                                margin="dense" id="name" label="Amount" type="number"
                                variant="outlined" onBlur={evt =>this.onBnkTransferToAccount(evt, toInd, 'amount')}
                                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : '' }
                                {...bnkAmtVal}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                                  )
                                }}
                            />
                        </Grid>
                        {arrayChecBnkAccnt && 
                          <Grid item xs={2} style={{position:'relative', left:'29px', top:'8px'}}>
                            <TextField
                                    margin="dense" id="name" label="Customer" type="text"
                                    variant="outlined" value={customer} style={{width:'86%'}}
                                    onChange={evt => this.onBnkTransferToAccount(evt, toInd, 'customer')}
                                />
                          </Grid>
                        }
                  </Grid>
                  )
              })
          }
      </>
    )
  }

  nonBankingTransferJSX = () => {
    const {
      fromAccntNonBnking, listacct: nonBnkListAcct, nonBankingTransferTo, fromNonBnkAmt, fromNonBankEmployer, removeAddAmt,
      frmNonBnkCustomer, fromEmpName
  } = this.state;
  const frmNonBnkAmtVal = removeAddAmt ? {value: Number(fromNonBnkAmt).toFixed(2)} : {};
  const checkTaxAccnt = nonBnkListAcct.some(n=>n.accountName.trim() === fromAccntNonBnking.trim() && n.ledger === 'Tax');
  const fromCustomerAutoClass = {labelStyle:{position:'relative', bottom: fromNonBankEmployer ? '0px' : '6px'},inputStyle: {height:'5rem'}};
    return(
      <>
          <Grid container spacing={3}>
              <Grid item xs={checkTaxAccnt ? 3: 4} style={{position:'relative', top:'2px'}}>
                    <TextField
                          value={fromAccntNonBnking ? fromAccntNonBnking : ''} onChange={evt =>this.handleChange(evt,'fromAccntNonBnking')}
                          variant="outlined" select label={<span style={{position:'relative', bottom: fromAccntNonBnking ? '0px' : '7px'}}>Account</span>}
                          style={{width:'65%', marginTop:'10px'}} InputProps={{ style: {height:'4.8rem' } }}
                    >
                          <MenuItem value={""}>Select Account</MenuItem>
                          {
                          nonBnkListAcct && nonBnkListAcct.length > 0 && nonBnkListAcct.map(v=>v.accountName)
                          .sort((anon,bnon) => (anon.toLowerCase() > bnon.toLowerCase()) - (anon.toLowerCase() < bnon.toLowerCase()))
                          .map(nonbnkacct => <MenuItem value={nonbnkacct}>{nonbnkacct}</MenuItem>)
                      }
                  </TextField>
                </Grid>
                <Grid item xs={4} style={{position:'relative', top:'10px'}}>
                    <Autocomplete
                      money={true} autoCompleteClass={fromCustomerAutoClass}
                      onSelectChange={this.onFromBnkTransferCoSelect} placeHoldCo="From Employer"
                      openCliDialog = {this.openCliTransferDialog} coName={fromEmpName}
                    />
                </Grid>
                <Grid item xs={2} style={{position:'relative', left:'14px'}}>
                  <TextField
                        margin="dense" id="name" label="Amount" type="number"
                        variant="outlined" onBlur={evt =>this.handleChange(evt, 'fromNonBnkAmt')}
                        defaultValue={fromNonBnkAmt === 0 || fromNonBnkAmt ? Number(fromNonBnkAmt).toFixed(2) : '' }
                        {...frmNonBnkAmtVal}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                          )
                        }}
                    />
                </Grid>
                {checkTaxAccnt && <Grid item xs={2} style={{position:'relative', left:'32px'}}>
                <TextField
                        margin="dense" id="name" label="Customer" type="text"
                        variant="outlined"
                        value={frmNonBnkCustomer}
                        fullWidth
                        onChange={evt => this.handleChange(evt, 'frmNonBnkCustomer')}
                    />
                </Grid>}
          </Grid>
          {
              nonBankingTransferTo.map((nonBnkAcnt, nonBnkToInd)=>{
                  const {employer, amount, customer, empName} = nonBnkAcnt;
                  const nbnkAmtVal = removeAddAmt ? {value: Number(amount).toFixed(2)} : {};
                  const toCustomerAutoClass = {labelStyle:{position:'relative', bottom: employer ? '0px' : '6px'},inputStyle: {height:'5rem'}};
                  return(
                    <Grid container spacing={3} style={{position:'relative', top:'5px', marginTop:'4px'}}>
                      <Grid item xs={checkTaxAccnt ? 3: 4}></Grid>
                        <Grid item xs={4} style={{position:'relative', top:'8px'}}>
                            <Autocomplete
                              money={true}
                              onSelectChange={this.onToCoSelect}
                              openCliDialog = {this.openCliTransferDialog}
                              coName={empName} toInd={nonBnkToInd} placeHoldCo="To Employer" autoCompleteClass={toCustomerAutoClass}
                            />
                        </Grid>
                        <Grid item xs={2} style={{position:'relative', left:'14px'}}>
                          <TextField
                                margin="dense" id="name" label="Amount" type="number"
                                variant="outlined" onBlur={evt =>this.onBnkTransferToAccount(evt, nonBnkToInd, 'amount')}
                                defaultValue={amount === 0 || amount ? Number(amount).toFixed(2) : '' }
                                {...nbnkAmtVal}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start"> <AttachMoneyIcon /> </InputAdornment>
                                  )
                                }}
                            />
                        </Grid>
                        {checkTaxAccnt &&
                        <Grid item xs={2} style={{position:'relative', left:'32px'}}>
                          <TextField
                              margin="dense" id="name" label="Customer" type="text"
                              variant="outlined" value={customer} fullWidth
                              onChange={evt => this.onBnkTransferToAccount(evt, nonBnkToInd, 'customer')}
                          />
                        </Grid>}
                        <Grid xs={1} item  style={{position:'relative', top:'13px', left:'42px'}}>
                          {nonBankingTransferTo.length - 1 === nonBnkToInd &&<Tooltip title="Add Ledger Item">
                            <Icon color="action" onClick={event => this.addLedgerItem()  }  style={{ fontWeight: 'bold', fontSize: '35px', cursor: 'pointer'}} >add_circle</Icon>
                          </Tooltip>}
                          {nonBnkToInd !== 0 && <Tooltip title="Delete Ledger Item">
                            <Icon onClick={evt => this.deleteLedgerItem(evt, nonBnkToInd)  } color="action" style={{ fontSize: '35px', cursor: 'pointer', fontWeight: 'bold' }} >delete_outline</Icon>
                          </Tooltip>}
                        </Grid>
                  </Grid>
                  )
              })
          }
      </>
    )
  }

  nonBnkTransferEnableButton = () => {
    const {nonBankingTransferTo, bnkDetails, fromAccntNonBnking, fromNonBankEmployer, fromNonBnkAmt, transactionDate, nonBnkTransfer} = this.state;
      const nonBnkTransferToBool = nonBankingTransferTo.every(p=> p.employer.trim() && typeof p.amount === 'number' && p.amount !== 0);
      const fromNonBnkAccntBool =  nonBnkTransfer && fromAccntNonBnking.trim() && fromNonBankEmployer.trim() && bnkDetails ? true : false;
      let nonBnkTransferBool = false;
      let tmpNonBnkFromAmt = Math.abs(fromNonBnkAmt);
      if(fromNonBnkAccntBool && nonBnkTransferToBool && transactionDate) {
        let reduceNonBnkVal = nonBankingTransferTo.reduce((a, b) => a + (Number(Math.abs(b['amount']).toFixed(2)) || 0), 0);
        reduceNonBnkVal = Number(reduceNonBnkVal.toFixed(2));
        const nonBnkPositive = this.checkAmountPositive();
        nonBnkTransferBool = nonBnkPositive && (reduceNonBnkVal === tmpNonBnkFromAmt) ? true : false;
      }
      return nonBnkTransferBool;
  }

  bnkTransferEnableButton = () => {
      const {bankingTransferTo, bnkDetails, fromAccntSel, fromEmployer, fromAmt, transactionDate, bnkTransfer} = this.state;
      const transferToBool = bankingTransferTo.every(p=> p.accountName.trim() && p.employer.trim() && typeof p.amount === 'number' && p.amount !== 0);
      const fromAccntBool =  bnkTransfer && fromAccntSel.trim() && fromEmployer.trim() && bnkDetails ? true : false;
      let bnkTransferBool = false;
      let tmpFromAmt = Math.abs(fromAmt);
      if(fromAccntBool && transferToBool && transactionDate) {
        let reduceVal = bankingTransferTo.reduce((a, b) => a + (Number(Math.abs(b['amount']).toFixed(2)) || 0), 0);
        reduceVal = Number(reduceVal.toFixed(2));
        const bnkPositive = this.checkAmountPositive();
        bnkTransferBool = bnkPositive && (reduceVal === tmpFromAmt) ? true : false;
      }
      return bnkTransferBool;
  }

  checkAmountPositive = () => {
    const {bankingTransferTo, nonBankingTransferTo, bnkTransfer, fromAmt, fromNonBnkAmt} = this.state;
    let amtPositive = false;
    if(bnkTransfer) {
      amtPositive = fromAmt >= 0 && bankingTransferTo.every(pbnk=> typeof pbnk.amount === 'number' && pbnk.amount >= 0);
    } else {
      amtPositive = fromNonBnkAmt >= 0 && nonBankingTransferTo.every(pnbnk=> typeof pnbnk.amount === 'number' && pnbnk.amount >= 0);
    }
    return amtPositive;
  }

  snackbarTransferhandleClientClose = () => {
    this.setState({clientTransfSnack: false});
  }

    render() {
        const { classes, openDialogTransfer } = this.props;
        const {
            transactionDate, bnkDetails,  bnkTransfer, nonBnkTransfer, clientTransfSnack, clientTransfSnckmessage, disableTransfer,
            openDialogTransferClients, chkAddTransferClient, compTransferName, compTransferFein, compTransferStatus, compTransferId
        } = this.state;
        let enableTransferSave = false;
        let avoidDupTransfer = disableTransfer ? {...disableHtmlElement} : {};
        if(chkAddTransferClient === 'add' && compTransferId.trim() && compTransferName.trim()) {
          if(compTransferStatus) {
              enableTransferSave = true;
          }
        }
        const enableBnkTransfer = this.bnkTransferEnableButton();
        const enableNonBnkTransfer = this.nonBnkTransferEnableButton();
        const chkAmount = this.checkAmountPositive();
        return (
          <>
            <Dialog
              open={openDialogTransfer}
              onClose={this.handleClose}
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth  maxWidth="lg"
              scroll='body'
              classes={{
                paperFullWidth: classes.paperFullWidth
              }} 
            >
            <DialogTitle component="div" className={`p-0 ${disableTransfer ? 'pointer-events-none opacity-50' : ''}`}>
            <AppBar style={{ color: 'white', backgroundColor:  platformHrThemeColor }} position="static" elevation={1}>
                <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16" style={{ overflowX: "scroll", overflowY: "hidden" }}>
                <div className="flex flex-1">
                Transfer Money
                </div>
                <IconButton color="inherit" ><Icon onClick={this.handleClose}>close</Icon></IconButton>
                </Toolbar>
            </AppBar>
            </DialogTitle>
            <DialogContent className={`p-16 sm:p-24 ${disableTransfer ? 'pointer-events-none opacity-50' : ''}`} style={{overflowY:'visible'}}>
              <div className="mb-16 sm:mb-8 flex">
              <Grid container spacing={3}>
              {!chkAmount && <Grid item xs={12} style={{color:'red', textAlign:'center', marginBottom:'14px'}}>Amount should be positive!</Grid>}
              <Grid item xs={12} sm={6}>
                  <Radio
                      checked={bnkTransfer === 'bnkTransfer'}
                      value={bnkTransfer}
                      onChange={event => this.handleBankingTransfer(event, "bnkTransfer")}
                  /> Banking Transfer
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Radio
                      checked={nonBnkTransfer === 'nonBnkTransfer'}
                      value={nonBnkTransfer}
                      onChange={event => this.handleBankingTransfer(event, "nonBnkTransfer")}
                  /> Non-Banking Transfer
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                    <TextField
                        id="date" label="Transaction Date"
                        type="date" value={moment(transactionDate).format('YYYY-MM-DD')}
                        variant="outlined" onChange={evt =>this.handleChange(evt, 'transactionDate')}
                        InputLabelProps={{ shrink: true}}
                      />
                </Grid>
                <Grid item xs={7} style={{position:'relative', left:'10px', bottom:'6px'}}>
                      <TextField
                          margin="dense" variant="outlined" id="bnkDetails"
                          label="Details" type="text"
                          InputProps={{ style:{ height: '54px', width:'72%'}}}
                          value={bnkDetails}
                          fullWidth
                          onChange={evt => this.handleChange(evt, 'bnkDetails')}
                      />
                </Grid>
              </Grid>
              {bnkTransfer && this.bankingTransferJSX()}
              {nonBnkTransfer && this.nonBankingTransferJSX()}
              </Grid>
              </div>
            </DialogContent>
            <DialogActions className={`${disableTransfer ? 'pointer-events-none opacity-50' : ''}`}>
              <Button 
                autoFocus  onClick={this.handleClose} 
                style={{color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize'}}
                >
                Cancel
              </Button>
              <Button 
                onClick={this.transferLedgerTransactions} 
                style={enableBnkTransfer || enableNonBnkTransfer ? {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...avoidDupTransfer}:
                {color:'#FFFFFF', backgroundColor: platformHrThemeColor, textTransform:'capitalize', ...disableHtmlElement}}
                >
                {disableTransfer ? 'Transfering...': 'Transfer'}
              </Button>
            </DialogActions>
            </Dialog>
            <div>
              {clientTransfSnack && snackBarComponent({
                      clientSnack: clientTransfSnack, duration: 400000, anchorigin: { vertical: 'top', horizontal: 'center'}, snackmessage: clientTransfSnckmessage,
                      snackbarhandleClose: this.snackbarTransferhandleClientClose
                  })
              }
              </div>
            {
                  openDialogTransferClients && 
                  <TransferMoneyClientDialog 
                      openDialogClients={openDialogTransferClients}
                      handleClose = {this.handleTransferClientClose}
                      handleChange = {this.handleChange} classes={classes}
                      saveClientData = {this.saveClientTransferData}
                      compId={compTransferId} compName={compTransferName} compFein={compTransferFein}
                      compStatus={compTransferStatus} chkAddClient={chkAddTransferClient} enableSave={enableTransferSave}
                      
                  />
            }
        </>
        )
    }
}

export default withStyles(useStyles)(TransferMoney);